import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import Item from 'app/main/model/Item';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stock-manage',
  templateUrl: './stock-manage.component.html',
  styleUrls: ['./stock-manage.component.scss'],
})
export class StockManageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  @BlockUI() blockUI: NgBlockUI;

  apiPath: string;

  contentHeader: object;
  itemList: Item[];

  isLoading: boolean;

  coreConfig: any;
  searchText: string;

  itemApiPath: string;

  public page = 1;
  public pageSize = 10;

  searchData: any = {};
  isAllChecked: any;

  defaultIMG: string = environment.defaultIMG;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = { searching: false, lengthChange: false };
  dtTrigger: Subject<any> = new Subject<any>();

  selectItemToReportList: any[] = [];

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _fileApiService: FilesApiService,
    private _globalFuncService: GlobalFuncService,
    private _coreConfigService: CoreConfigService
  ) {
    // this._unsubscribeAll = new Subject();
    this.itemApiPath = 'item';
    this.SetLoadingState();
    this.initData({ SortPath: 'CreateDate', Direction: 1 });
  }

  SetLoadingState() {
    // this.blockUI.start()
    this.isLoading = true;
  }

  SetLoadedState() {
    this.blockUI.stop();
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this.contentHeader = {
      headerTitle: 'General.StockManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[2, 'asc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        {
          orderable: false,
          targets: 0,
        },
        { orderable: false, targets: 1 },
        { name: 'ItemCode', targets: 2 },
        { name: 'SellingPrice', targets: 3 },
        { name: 'Quantity', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];
        self.SetLoadingState();

        let defaultSortName = 'ItemCode';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.pageSize = dataTablesParameters.length;
        this.searchData.isDelete = false;
        this.searchData.verbose = false;

        console.log(this.searchData);
        this._apiService
          .GetAllData(this.itemApiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.itemList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    this.searchData = {
      SortPath: 'CreateDate',
      Direction: 1,
      isDelete: false,
    };

    if (obj) {
      console.log(obj.params);
      console.log('have searchtext: ', obj.searchText);

      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'CreateDate',
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: 'CreateDate',
        Direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;

    this.loadData(this.searchData);
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: this.apiPath + 'Code',
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  getImages(guid: string, params): string {
    return this._fileApiService.getImage(guid, params);
  }

  formatToMoney(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  checkAllRecord(event: any) {
    this.selectItemToReportList = [];
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: any) => {
      this.isAllChecked = $('th', dtInstance.table(0).node()).find(
        '[type="checkbox"]'
      )[0]['checked'];
      var elts = [];
      $('td', dtInstance.table(0).node()).find('[type="checkbox"]');
      var el = $('td', dtInstance.table(0).node()).find('[type="checkbox"]');
      elts.push(el);
      var temp = elts[0];
      for (const elt of temp) {
        elt.checked = this.isAllChecked;
        this.selectItemToReportList = this.itemList.map((a) => a.id);
        //$("#txt-is_selected-" + elt.dataset['id']).attr('checked', 'checked');
      }
    });
  }

  checkRecord(event: any, id: string, index: number) {
    console.log(event.target.checked, id);
    this.selectItemToReportList.push(id);
  }

  exportReport() {
    console.log(this.selectItemToReportList);
  }

}
