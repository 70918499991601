<section>
  <p class="text-primary">{{'EKYC.FileHistory' | translate }}</p>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>{{ 'EKYC.Table.Detail' | translate }}</th>
        <th>{{ 'EKYC.Table.File' | translate }}</th>
        <th>{{ 'EKYC.Table.UpdatedBy' | translate }}</th>
        <th>{{ 'EKYC.Table.Date' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="w-100" *ngIf="isLoading">
        <td class="text-center" *ngFor="let num of [].constructor(5)">
          <div class="skeleton-loading"></div>
        </td>
      </tr>

      <tr *ngIf="fileDetailList.length < 1">
        <td colspan="100%" class="text-center">
          <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
        </td>
      </tr>

      <tr *ngFor="let fileDetail of fileDetailList; let ind = index">
        <td class="text-center">{{ ind + 1 }}</td>

        <td>{{ fileDetail.verifyTypeEnum }}</td>
        <td>
          <a [href]="getImage(fileDetail.fileId)" class="glightbox" data-type="image">
            <!-- <img [src]="fileDetail.fileId" class="image-evidence" alt=""> -->
            Show Image
          </a>
        </td>
        <td>{{ fileDetail.createBy }}</td>
        <td>
          <div class="text-dark">
            <span *ngIf="currentLang == 'th'">
              {{ fileDetail.createDate | thaidate : 'DD MMM YYYY' }}</span>
            <span *ngIf="currentLang == 'en'">
              {{ fileDetail.createDate | date : 'dd MMM YYYY' }}
            </span>

          </div>
          <small>{{ fileDetail.createDate | date : 'HH:mm' }} <span *ngIf="currentLang == 'th'">น.</span></small>
        </td>
      </tr>
    </tbody>
  </table>

  <p class="text-primary">{{'EKYC.BankAccount' | translate }}</p>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>{{ 'EKYC.Table.BankAccountNo' | translate }}</th>
        <th>{{ 'EKYC.Table.BankAccountName' | translate }}</th>
        <th>{{ 'EKYC.Table.BankName' | translate }}</th>
        <th>{{ 'EKYC.Table.Date' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="w-100" *ngIf="isLoading">
        <td class="text-center" *ngFor="let num of [].constructor(5)">
          <div class="skeleton-loading"></div>
        </td>
      </tr>

      <tr *ngIf="bankAccountList.length < 1">
        <td colspan="100%" class="text-center">
          <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
        </td>
      </tr>

      <tr *ngFor="let bankAccount of bankAccountList; let ind = index">
        <td class="text-center">{{ ind + 1 }}</td>
        <td>{{ bankAccount.bankAccountNo }}</td>
        <td>{{ bankAccount.bankAccountName }}</td>
        <td>{{ bankAccount.bankName }}</td>
        <td>
          <div class="text-dark">
            <span *ngIf="currentLang == 'th'">
              {{ bankAccount.createDate | thaidate : 'DD MMM YYYY' }}</span>
            <span *ngIf="currentLang == 'en'">
              {{ bankAccount.createDate | date : 'dd MMM YYYY' }}
            </span>

          </div>
          <small>{{ bankAccount.createDate | date : 'HH:mm' }} <span *ngIf="currentLang == 'th'">น.</span></small>
        </td>
      </tr>
    </tbody>
  </table>
</section>