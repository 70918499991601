import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  contentHeader: ContentHeader;
  currentLang: string = '';

  formGroup: FormGroup;
  isSubmit: boolean = false;
  invalidDate: boolean = false;

  startDateOptions: FlatpickrOptions;
  endDateOptions: FlatpickrOptions;

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder
  ) {
    this.formGroup = this._formBuilder.group({
      name: ['', [Validators.required]],
      startDate: ['', []],
      endDate: ['', []],
      eventType: ['', []],
      pReward: ['', []],
      apReward: ['', []],
      mpReward: ['', []],
    });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Event Manage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  openCancelModal() {}

  openConfirmModal() {}

  onDateFromChange(event: any) {}

  onDateToChange(event: any) {}
}
