<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        <!-- {{ "BookingCalendar.Modal.Header" | translate }} -->
        Add customer
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="formGroup">
        <div class="card-body row">
            <div class="col-12 col-sm-12 col-md-3">
                <div class="scope-custom">
                    <label for="first-name-vertical">{{
                        "General.Search" | translate
                        }}</label>
                    <div class="input-group">
                        <select name="sorting" class="custom-select" #typeSelect>
                            <option value="searchByCode">
                                Repove Code
                            </option>
                            <option value="searchByName">
                                Customer Name
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <div class="scope-custom">
                    <label for="first-name-vertical">{{
                        "General.Search" | translate
                        }}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" #searchBar name="name"
                            placeholder="{{ 'General.Search' | translate }}" />
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 align-self-end">
                <button (click)="SubmitSearch()" class="btn btn-primary" style="margin-left: 0.5rem">
                    <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                </button>
            </div>
        </div>
    </form>

    <table datatable [dtOptions]="dtOptions" class="table" id="customerAddTable">
        <thead>
            <tr>
                <th class="text-center col-1">#</th>
                <th class="col-2">Repove Code</th>
                <th class="col-6">Customer</th>
                <th class="col-2">Rank</th>
                <th class="text-center col-1">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="customerList.length < 1 && !isLoading">
                <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                </td>
            </tr>

            <tr *ngFor="let customer of customerList; index as ind">
                <td class="text-center">{{ ind + 1 }}</td>
                <td>
                    <a [routerLink]="['/customer/detail/' + customer.id]" target="_blank"
                        ngbTooltip="{{ customer.repoveCode}}">
                        <span class="text-primary text-truncate"> {{ customer.repoveCode }}</span>
                    </a>
                </td>
                <td>
                    <a [routerLink]="['/customer/detail/' + customer.id]" target="_blank"
                        ngbTooltip="{{ customer.detail }}">
                        <span class="text-primary text-truncate"> {{ customer.detail }}</span>
                    </a>
                </td>
                <td>
                    <p>{{ customer.rankName }}</p>
                </td>
                <td class="text-center">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-primary mr-50"
                        (click)="openCustomerAddModal(customer.id, customer.detail)"><i data-feather="plus" class="text-primary"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>