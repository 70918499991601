<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        Evidence
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body ">
    <div class="row">
        <div class="col-2">
            <label for="name">ชื่อ</label>
            <p>{{ customerData.customerFirstName? customerData.customerFirstName : '-' }}</p>
        </div>
        <div class="col-2">
            <label for="name">นามสกุล</label>
            <p>{{ customerData.customerLastName? customerData.customerLastName : '-' }}</p>
        </div>
        <div class="col-2">
            <label for="name">Repove Code</label>
            <p>{{ customerData.repoveCode? customerData.repoveCode : '-' }}</p>
        </div>
        <div class="col-2">
            <label for="name">Rank</label>
            <p>{{ customerData.rankName? customerData.rankName : '-' }}</p>
        </div>
        <div class="col-2">
            <label for="name">สถานะ</label>
            <p>{{ customerData.approveStatus? customerData.approveStatus : '-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="name">คำอธิบาย</label>
            <p>{{ customerData.remarks? customerData.remarks : '-' }}</p>
        </div>
    </div>

    <div class="d-flex mt-2"  ngbAutofocus>
        <ng-container *ngIf="imageEvidenceList.length >= 1">
            <ng-container *ngFor="let image of imageEvidenceList">
                <a [href]="image.file" class="glightbox" data-type="image" data-width="500px">
                    <img [src]="image.file" class="image-evidence" alt="">
                </a>
            </ng-container>
        </ng-container>
    </div>


</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
        Close
    </button>
</div>