<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- <app-search-panel [forCompanentName]="componentName" [pathUrl]="pathUrl + '/create'" [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate" [isShowSortByGroup]="false" (callDataFromAPI)="initData($event)"
      (ActiveStatusFilter)="ActiveStatusFilter($event)"></app-search-panel> -->

    <section id="knowledge-base-search">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="col-2 px-0 d-flex justify-content-start">
                <a [routerLink]="[pathUrl + '/create']" class="btn btn-primary">
                  <i data-feather="plus" class="mr-1"></i>{{ "General.Create" | translate }}
                </a>
              </div>
            </div>
            <div class="card-body row">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="scope-custom">
                  <label for="">{{ 'General.SearchBy' | translate }}</label>
                  <select class="custom-select" [(ngModel)]="searchByName">
                      <option [ngValue]="0">{{ 'ViewTable.CustomerName' | translate }}</option>
                      <option [ngValue]="1">{{ 'ViewTable.CustomerCode' | translate }}</option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-4">
                <div class="scope-custom">
                  <label for="first-name-vertical">{{
                    "General.Search" | translate
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      #searchbar
                      name="searchbar"
                      placeholder="{{ 'General.Search' | translate }}"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-4">
                <div class="scope-custom">
                  <label for="first-name-vertical">{{
                    "General.Status" | translate
                    }}</label>
                  <select name="sorting" class="custom-select" #activeFil>
                    <option value="all">{{ "General.All" | translate }}</option>
                    <option value="active">
                      {{ "Status.Active" | translate }}
                    </option>
                    <option value="inactive">
                      {{ "Status.Inactive" | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="card-body row">
              <div class="col-12 px-0 d-flex justify-content-between">
                <!-- <div class="col-3 d-flex justify-content-start my-auto">
                </div> -->
                <div class="col-12 px-0 d-flex justify-content-center">
                  <button (click)="!isLoading ? ResetSearch() : false" [disabled]="isLoading" class="btn btn-secondary"
                    style="margin-right: 0.5rem">
                    <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" | translate }}
                  </button>

                  <button (click)="!isLoading ? SubmitSearch() : false" [disabled]="isLoading" class="btn btn-primary"
                    style="margin-left: 0.5rem">
                    <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="card">
        <div class="card-body">
          <div>
            <table [dtOptions]="dtOptions" class="table" datatable>
              <thead>
                <tr class="text-center" style="width: 100% !important">
                  <th class="text-center" width="4%">No.</th>
                  <th class="text-center" width="10%">Code</th>
                  <th class="text-center" width="28%">Title</th>
                  <th class="text-center" width="10%">Email</th>
                  <th class="text-center" width="15%">Status</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="w-100" *ngIf="isLoading">
                  <td class="text-center" *ngFor="let num of [].constructor(6)">
                    <div class="skeleton-loading"></div>
                  </td>
                </tr>

                <tr *ngIf="itemList.length < 1 && !isLoading">
                  <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                  </td>
                </tr>

                <tr *ngFor="let item of itemList; let ind = index">
                  <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>
                  <td class="text-center">
                    <a [routerLink]="[detailPathUrl + '/' + item.id]">
                      {{ item.repoveCode }}
                    </a>
                  </td>
                  <td>
                    <div class="d-block">
                      <p class="mb-0">
                        {{
                        item.islegalEntity
                        ? item.businessName
                        : item.customerFirstName +
                        " " +
                        item.customerLastName
                        }}
                      </p>
                      <small class="mb-0" *ngIf="item.islegalEntity">
                        ({{
                        item.isHeadBranch
                        ? ("Customer.headOffice" | translate)
                        : ("Customer.branch" | translate) +
                        ": " +
                        item.branchNumber.toString().padStart(5, "0") + ", " + item.branchName
                        }})
                      </small>

                      <!-- <small class="mb-0" *ngIf="!(item.isHeadBranch != true && item.branchNumber == 0 && )">
                        ({{
                        item.isHeadBranch
                        ? ("Customer.headOffice" | translate)
                        : ("Customer.branch" | translate) +
                        ": " +
                        item.branchNumber.toString().padStart(5, "0") + ", " + item.branchName
                        }})
                      </small> -->
                    </div>
                  </td>
                  <td>{{ item.contactEmail ? item.contactEmail : "-" }}</td>
                  <td class="text-center">
                    <div class="form-group my-auto">
                      <div ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);"
                          class="d-flex justify-content-center text-secondary" id="dropdownBrowserState"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{
                            'text-success': item.isActive,
                            'text-danger': !item.isActive
                          }">
                          <div>
                            {{
                            (item.isActive
                            ? "Status.Active"
                            : "Status.Inactive"
                            ) | translate
                            }}
                          </div>
                          <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                          <a ngbDropdownItem *ngIf="item.isActive" class="d-flex align-items-center"
                            (click)="openIsActiveModal(item, false)">
                            <i data-feather="eye-off" class="mr-50"></i>
                            {{ "Status.Inactive" | translate }}
                          </a>
                          <a ngbDropdownItem *ngIf="!item.isActive" class="d-flex align-items-center"
                            (click)="openIsActiveModal(item, true)">
                            <i data-feather="eye" class="mr-50"></i>
                            {{ "Status.Active" | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class=" d-flex justify-content-center">
                      <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                        [routerLink]="[detailPathUrl + '/' + item.id]"><i data-feather="eye"></i></a>
                      <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                        [routerLink]="[editPathUrl + '/' + item.id]"><i data-feather="edit"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>