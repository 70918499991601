<div class="downline-content d-flex">
  <div class="col-md-8 pe-3">
    <div class="table-top d-flex justify-content-between">
      <p class="title">{{ "MLM.YourCrew" | translate }}</p>
      <div class="d-flex search">
        <div class="period-pick" ngbDropdown>
          <button ngbDropdownToggle aria-expanded="false">
            <span class="img">
              <img src="../../../../assets/images/sv-bonus/icon/icon-calendar.svg" alt="" />
            </span>
            <span class="text">{{ periodTeam }}</span>
          </button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li class="dropdown-item" ngbDropdownItem (click)="teamPeriod(item.id, item.startDate, item.endDate)"
              *ngFor="let item of periodList">
              {{ item.startDate | date: "dd/MM/yyyy" }} - {{ item.endDate | date: "dd/MM/yyyy" }}
            </li>
          </ul>
        </div>
        <div ngbDropdown>
          <button class="sort" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
            <p class="my-auto">
              {{ "General.SortBy" | translate }}&nbsp;<span>{{
                sortSV == 0 ? ('MLM.HighestSV' | translate) : ('MLM.LowestSV' | translate)
                }}</span>
            </p>
          </button>
          <ul class="dropdown-menu sort-menu" ngbDropdownMenu>
            <li class="dropdown-item" ngbDropdownItem (click)="selectSort(1)">
              {{ 'MLM.HighestSV' | translate }}
            </li>
            <li class="dropdown-item" ngbDropdownItem (click)="selectSort(0)">
              {{ 'MLM.LowestSV' | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <table datatable [dtOptions]="dtOptions" class="table-downline w-100">
      <thead>
        <tr>
          <th width="40%" class="start">
            <div class="pl-1">{{ "ViewTable.Name" | translate }}</div>
          </th>
          <th width="15%">{{ "ViewTable.Status" | translate }}</th>
          <!-- <th width="15%">{{ 'Address.province' | translate }}</th> -->
          <th width="15%" class="text-end">
            {{ "MLM.SVFormCrew" | translate }}
          </th>
          <th width="15%" class="text-end end" style="padding-right: 1rem !important;">
            {{ "MLM.BonusFormCrew" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="w-100" *ngIf="isLoading">
          <td class="text-center" *ngFor="let num of [].constructor(5)">
            <div class="skeleton-loading"></div>
          </td>
        </tr>

        <tr *ngIf="downlineList.length < 1 && !isLoading">
          <td colspan="100%" class="text-center">
            <div>{{ "ViewTable.NoItemPreview" | translate }}</div>
          </td>
        </tr>
        <tr *ngFor="let downline of downlineList; let ind = index">
          <td>
            <div *ngIf="ind <= 2" class="avatar-table">
              <span [ngClass]="{
                  rank1: ind == 0,
                  rank2: ind == 1,
                  rank3: ind == 2
                }">{{ ind + 1 }}</span>
              <img src="../../../../assets/product/product-set-1.png" alt="user-profile" />
            </div>

            <img *ngIf="ind >= 3" src="../../../../assets/product/product-set-1.png" alt="user-profile" />
            <a [routerLink]="['/customer/detail/' + downline.id]" target="_blank">
              {{ downline?.detail }}
            </a>
          </td>
          <td>{{ downline?.rankName }}</td>
          <!-- <td>{{ downline?.province }}</td> -->
          <td class="text-end pe-3">
            {{ (downline.sv ? downline.sv : 0) | number }} SV
          </td>
          <td class="text-end pe-3">
            {{ (downline.bonus ? downline.bonus : 0) | number }}
            {{ "General.Baht" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-4 ps-3">
    <div class="overview">
      <p class="title">{{ "MLM.OverviewCrew" | translate }}</p>
      <div class="summary">
        <p class="title">{{ "MLM.TodayBonusFromYourCrew" | translate }}</p>
        <div class="summary-content d-flex justify-content-center">
          <div class="w-50">
            <span>{{ (this.totalSV ? this.totalSV : 0) | number }}</span>
            <p>SV</p>
          </div>
          <div class="w-50 border-left">
            <span>{{ (this.totalSV ? this.totalBonus : 0) | number }}</span>
            <p>{{ "MLM.Bonus" | translate }}</p>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="!isVerify" class="detail">
        <p class="not-verify text-center">ยังไม่มีข้อมูลทีม</p>
      </div> -->

      <!-- *ngIf="isVerify" -->
      <div class="detail">
        <div class="d-flex justify-content-between">
          <p class="mb-0">{{ "MLM.NewMember" | translate }}</p>
          <div class="avatar-group d-flex">
            <ng-container *ngFor="let newUser of newUsersList">
              <div class="avatar">
                <img src="../../../../assets/product/product-set-1.png" alt="user" />
              </div>
            </ng-container>

            <ng-container *ngIf="newUsersList.length > 3">
              <button class="hidden-avatar d-flex justify-content-between mr-1" (click)="openModal(newMemberModal)">
                +{{
                newUsersList.length - 3 < 99 ? newUsersList.length - 3 : 99 }} </button>
            </ng-container>
          </div>
        </div>
        <div class="d-flex justify-content-between bd">
          <p class="mb-0">
            {{ "MLM.HighestBonusIn" | translate }} <br />
            {{ "MLM.ThisWeek" | translate }}
          </p>
          <div class="user-profile">
            <ng-container *ngIf="topWeekCustomerList.length >= 1">
              <img src="../../../../assets/product/product-set-1.png" alt="user" />
              <button (click)="openModal(weekModal)">
                {{ topWeekCustomerList ? topWeekCustomerList[0].detail : '-' }}
              </button>
            </ng-container>

          </div>
        </div>
        <div class="d-flex justify-content-between">
          <p class="mb-0">
            {{ "MLM.HighestBonusIn" | translate }}<br />
            {{ "MLM.ThisMonth" | translate }}
          </p>
          <div class="user-profile">
            <ng-container *ngIf="topMonthCustomerList.length >= 1">
              <img src="../../../../assets/product/product-set-1.png" alt="user" />
              <button (click)="openModal(monthModal)">
                {{ topMonthCustomerList ? topMonthCustomerList[0].detail : '-' }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newMemberModal let-modal>
  <div class="modal-header d-flex justify-content-between">
    <p class="modal-title">
      {{ "MLM.NewMember" | translate }}
    </p>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <i data-feather="x"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let newUser of newUsersList" class="new-member d-flex">
      <img src="assets/img/repove_default_img.png" alt="" />
      <div>
        <a [routerLink]="['/customer/detail/' + newUser.id]" target="_blank">
          <p class="name">{{ newUser.name }}</p>
        </a>

        <p class="date">
          {{ "MLM.JoinWhen" | translate }}:
          <span>{{ newUser.date | date : "dd MMM YYYY" }}</span>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #weekModal let-modal>
  <div class="modal-header">
    <p class="modal-title">
      {{ "MLM.HighestSalesInThisWeek" | translate }}
    </p>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <i data-feather="x"></i>
    </button>
  </div>
  <div class="modal-body px-2">
    <table class="table-downline best-seller w-100">
      <thead>
        <tr>
          <th width="40%" class="start">
            <div class="pl-1">{{ "ViewTable.Name" | translate }}</div>
          </th>
          <!-- <th width="15%">{{ "ViewTable.Status" | translate }}</th> -->
          <!-- <th width="15%">{{ 'Address.province' | translate }}</th> -->
          <th width="15%" class="text-end">
            {{ "MLM.SVFormCrew" | translate }}
          </th>
          <th width="15%" class="text-end pr-1 end">
            {{ "MLM.BonusFormCrew" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of topWeekCustomerList; let ind = index">
          <td>
            <div *ngIf="ind <= 2" class="avatar-table">
              <span [ngClass]="{
                  rank1: ind == 0,
                  rank2: ind == 1,
                  rank3: ind == 2
                }">{{ ind + 1 }}</span>
              <img src="assets/img/repove_default_img.png" alt="user-profile" />
            </div>
            <a [routerLink]="['/customer/detail/' + item.id]" target="_blank">{{ item.detail }}</a>
          </td>
          <td class="text-end pe-2">{{ item.sv | number }} SV</td>
          <td class="text-end pe-2">
            {{ item.bonus | number }}
            {{ "General.Baht" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #monthModal let-modal>
  <div class="modal-header d-flex justify-content-between">
    <p class="modal-title">
      {{ "MLM.HighestSalesInThisMonth" | translate }}
    </p>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <i data-feather="x"></i>
    </button>
  </div>
  <div class="modal-body px-2">
    <table class="table-downline best-seller w-100">
      <thead>
        <tr>
          <th width="40%" class="start">
            <div class="pl-1">{{ "ViewTable.Name" | translate }}</div>
          </th>
          <!-- <th width="15%">{{ "ViewTable.Status" | translate }}</th> -->
          <!-- <th width="15%">{{ 'Address.province' | translate }}</th> -->
          <th width="15%" class="text-end">
            {{ "MLM.SVFormCrew" | translate }}
          </th>
          <th width="15%" class="text-end pr-1 end">
            {{ "MLM.BonusFormCrew" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of topMonthCustomerList; let ind = index">
          <td>
            <div *ngIf="ind <= 2" class="avatar-table">
              <span [ngClass]="{
                  rank1: ind == 0,
                  rank2: ind == 1,
                  rank3: ind == 2
                }">{{ ind + 1 }}</span>
              <img src="assets/img/repove_default_img.png" alt="user-profile" />
            </div>
            <a [routerLink]="['/customer/detail/' + item.id]" target="_blank">{{ item.detail }}</a>
          </td>
          <td class="text-end pe-2">{{ item.sv | number }} SV</td>
          <td class="text-end pe-2">
            {{ item.bonus | number }}
            {{ "General.Baht" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>