import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BankList } from 'app/main/model/BankAccount';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { EKYCDetail, EKYCStatusEnum } from 'app/main/model/EKYC';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ekyc-form-modal',
  templateUrl: './ekyc-form-modal.component.html',
  styleUrls: ['./ekyc-form-modal.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class EkycFormModalComponent implements OnInit {
  @Input() isConfirm: boolean;
  @Input() confirmUrl: string;
  @Input() title: string;

  @Output() cancelFunc: EventEmitter<any> = new EventEmitter();
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  stateEnum = EKYCStatusEnum;

  EKYCDetail: EKYCDetail;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });

    this.bankNameList = BankList;

    this.bankAccountForm = this._formBuilder.group({
      bankId: [null], // Validators.required
      accountNumber: [null], // Validators.required
      accountName: [null], // Validators.required
    });

    this.isEditingSubject = new BehaviorSubject<boolean>(false);
    this.isDataSavedSubject = new BehaviorSubject<boolean>(false);

    this.isEditing = this.isEditingSubject.asObservable();
    this.isDataSaved = this.isDataSavedSubject.asObservable();

    this.SetDisabledForm();
  }

  closeModal(): void {
    this.cancelFunc.emit();
    // this.activeModal.close();
    this._modalService.dismissAll();
  }

  callBack(): void {
    this.callBackFunc.emit();
    this.activeModal.close();
    // this.router.navigate([this.confirmUrl]);
  }

  pidImage: string;
  pidWithPersonImage: string;
  bookBankImage: string;

  pidImageFile: File;
  pidWithPersonImageFile: File;
  bookBankImageFile: File;

  og_pidImage: string;
  og_pidWithPersonImage: string;
  og_bookBankImage: string;

  isPidImageInvalid: boolean = false;
  isPidWithPersonImageInvalid: boolean = false;

  isPidImageChanged: boolean = false;
  isPidWithPersonImageChanged: boolean = false;
  isBookBankImageChanged: boolean = false;

  isPidStatusChanged: boolean = false;
  isPidWithPersonStatusChanged: boolean = false;
  isBookBankStatusChanged: boolean = false;

  pidImageStateEnum: number = 0;
  pidWithPersonImageStateEnum: number = 0;
  bookBankImageStateEnum: number = 0;

  previewIMG: string;
  previewIMG_file: File;

  maxFileSize: number = environment.limitFileSize.maxImageSizeInBytes;

  bankAccountList: any[] = [];
  isLoading: boolean = false;
  isSubmit: boolean = false;
  isDataChanged: boolean = false;

  public isEditing: Observable<boolean>;
  public isDataSaved: Observable<boolean>;

  private isEditingSubject: BehaviorSubject<boolean>;
  private isDataSavedSubject: BehaviorSubject<boolean>;

  // @Output() setEditing: EventEmitter<any> = new EventEmitter();

  page: number = 1;
  pageSize: number = 10;
  searchData: any = {};

  bankAccountForm: FormGroup;
  bankNameList: { id: number; name: string }[] = [];
  originalData: {
    bankId: number;
    accountNumber: string;
    accountName: string;
  };

  selectedFileEnum: string;

  customerId: string;

  public get isEditingValue(): boolean {
    return this.isEditingSubject.value;
  }

  public get isDataSavedValue(): boolean {
    return this.isDataSavedSubject.value;
  }

  SetDisabledForm(): void {
    this.bankAccountForm.controls.bankId.disable();
    this.bankAccountForm.controls.accountNumber.disable();
    this.bankAccountForm.controls.accountName.disable();
    this.isEditingSubject.next(false);
  }

  SetEnabledForm(): void {
    this.bankAccountForm.controls.bankId.enable();
    this.bankAccountForm.controls.accountNumber.enable();
    this.bankAccountForm.controls.accountName.enable();
    this.isEditingSubject.next(true);
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.bankAccountForm.valueChanges.subscribe((res) => {
      this.isDataChanged = true;
    });
  }

  editMode() {
    this.isEditingSubject.next(true);
    this.SetEnabledForm();
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  onFileSelected(event, fileEnum: string, modal) {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.size <= this.maxFileSize) {
      reader.onloadend = () => {
        if (fileEnum == 'pidImage') {
          this.isPidImageInvalid = false;
        } else if (fileEnum == 'pidWithPersonImage') {
          this.isPidWithPersonImageInvalid = false;
        }

        this.previewIMG = reader.result as string;
        this.previewIMG_file = file;
        this.selectedFileEnum = fileEnum;
      };
      reader.readAsDataURL(file);
      this.openChangePictureModal(modal);
    } else {
      console.error(
        `File size exceeds the maximum limit of ${this.bytesToSizes(
          this.maxFileSize
        )}.`
      );
      this._componentsService.ErrorSwal(
        'Invalid File Size',
        `File size exceeds the maximum limit of ${this.bytesToSizes(
          this.maxFileSize
        )}. `
      );
    }
  }

  viewImage(tempimage) {
    console.log('view image', tempimage);
  }

  openChangePictureModal(modal) {
    this.activeModal = this._modalService.open(modal, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
  }

  get f() {
    return this.bankAccountForm.controls;
  }

  resetTab() {
    this.isEditingSubject.next(false);
    this.SetDisabledForm();
  }

  ngOnDestroy(): void {
    this.isEditingSubject.next(false);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.SetDisabledForm();
  }

  onSelectBankId() {}

  onSelectAccountNumber() {}

  onSelectAccountName() {}

  editBankAccount() {}

  clearDataToOriginal() {
    this.bankAccountForm.patchValue({
      bankId: this.originalData.bankId,
      accountNumber: this.originalData.accountNumber,
      accountName: this.originalData.accountName,
    });
  }

  checkValidateImage(): boolean {
    let havePidImage: boolean = !!this.og_pidImage || !!this.pidImageFile;
    let havePidWithPersonImage: boolean =
      !!this.og_pidWithPersonImage || !!this.pidWithPersonImageFile;

    this.isPidImageInvalid = !havePidImage;
    this.isPidWithPersonImageInvalid = !havePidWithPersonImage;

    return havePidImage && havePidWithPersonImage;
  }

  Submit() {
    this.isSubmit = true;

    if (this.bankAccountForm.invalid || !this.checkValidateImage()) {
      return;
    }

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant("Modal.ConfirmCreate");
    modalRef.componentInstance.detail =
    this._translateService.instant("Modal.AreYouSureToCreate");
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.SaveKYCData();
      console.log(res);
    });
  }

  Cancel() {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant("Modal.CancelManagePage");
    modalRef.componentInstance.detail =
    this._translateService.instant("Modal.PressSubmitToCancel");
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.isEditingSubject.next(false);
      this.SetDisabledForm();
      this.closeModal();
    });
  }

  SavePicture() {
    if (this.selectedFileEnum == 'pidImage') {
      this.pidImage = this.previewIMG;
      this.pidImageFile = this.previewIMG_file;

      this.isPidImageChanged = true;
    } else if (this.selectedFileEnum == 'pidWithPersonImage') {
      this.pidWithPersonImage = this.previewIMG;
      this.pidWithPersonImageFile = this.previewIMG_file;

      this.isPidWithPersonImageChanged = true;
    } else if (this.selectedFileEnum == 'bookBankImage') {
      this.bookBankImage = this.previewIMG;
      this.bookBankImageFile = this.previewIMG_file;

      this.isBookBankImageChanged = true;
    }

    this.isDataChanged = true;
    this.selectedFileEnum = '';
    // this._modalService.dismissAll();
  }

  SaveKYCData() {
    if (this.isPidImageChanged) {
      this.UploadImage(
        'ekyc',
        this.customerId,
        'pidImage',
        0,
        this.pidImageFile
      );
    }

    if (this.isPidWithPersonImageChanged) {
      this.UploadImage(
        'ekyc',
        this.customerId,
        'PidWithPersonImage',
        0,
        this.pidWithPersonImageFile
      );
    }

    if (this.isBookBankImageChanged) {
      this.UploadImage(
        'ekyc',
        this.customerId,
        'BookBankImage',
        0,
        this.bookBankImageFile
      );
    }

    this._apiService
      .UpdateDataById(
        'ekyc/bank/customer',
        this.customerId,
        this.bankAccountForm.value
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.isDataSavedSubject.next(true);
          this.callBackFunc.emit();
          this._componentsService.SuccessSwal();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );

    this.imagePatchEnum();
  }

  UploadImage(
    tablename: string,
    contentId: string,
    flleproperty: string,
    ordinal: number,
    file: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, rejects) => {
      this._fileService
        .AddFile(tablename, contentId, flleproperty, ordinal, formData)
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getBankImg(bankId: number) {
    return this._globalFuncService.getBankImg(bankId.toString());
  }

  getStatusLabel(state: number): any {
    switch (state) {
      case this.stateEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case this.stateEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case this.stateEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }

  onFileStatusChange(fileEnum: string, value: string) {
    console.log(fileEnum);
    console.log(value);
    if (fileEnum == 'pidImage') {
      this.pidImageStateEnum = parseInt(value);
    } else if (fileEnum == 'pidWithPersonImage') {
      this.pidWithPersonImageStateEnum = parseInt(value);
    } else if (fileEnum == 'bookBankImage') {
      this.bookBankImageStateEnum = parseInt(value);
    }
  }

  selectedValue: string = '0';
  statusOptions = [
    { id: 0, name: this.getStatusLabel(0) },
    { id: 1, name: this.getStatusLabel(1) },
    { id: 2, name: this.getStatusLabel(2) },
  ];

  confirmFileStatusChange(fileEnum: string, value: string) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    console.log(fileEnum);
    console.log(this.selectedValue);
    console.log(value);
    const ogValue = this.selectedValue;

    modalRef.componentInstance.title = 'Confirm File Status';
    modalRef.componentInstance.detail = 'Sure to confirm file status';

    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this[fileEnum + 'StateEnum'] = value;
      this.onFileStatusChange(fileEnum, value);
      console.log(value);
      console.log('confirm');
    });
    modalRef.componentInstance.cancelFunc.subscribe((res) => {
      this.selectedValue = '0';
      this.onFileStatusChange(fileEnum, this.selectedValue);
      console.log(value);
      console.log('cancel');
    });
  }

  imagePatchEnum() {
    this.handleImagePatch('idcardimage', this.isPidStatusChanged);
    this.handleImagePatch('personalwithidcardimage', this.isPidWithPersonStatusChanged);
    this.handleImagePatch('bookbankimage', this.isBookBankStatusChanged);
  }
  
  private handleImagePatch(filename: string, statusChanged: boolean) {
    if (statusChanged) {
      if (this.pidImageStateEnum === this.stateEnum.Approved) {
        this.patchFileStatusEnum(filename, 'approve');
      } else if (this.pidImageStateEnum === this.stateEnum.Reject) {
        this.patchFileStatusEnum(filename, 'reject');
      }
    }
  }

  private patchFileStatusEnum(filename: string, status: string): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .patch(`${status}/${filename}/customer/${this.customerId}`)
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  onBankStatusChange(value) {
    this.EKYCDetail.verifyBookbankDetailStatusEnum = value;
  }
}
