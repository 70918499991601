import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-customer-modal',
  templateUrl: './add-customer-modal.component.html',
  styleUrls: ['./add-customer-modal.component.scss']
})
export class AddCustomerModalComponent implements OnInit {

  isLoading: boolean = false;
  customerList: Customer[] = [];

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  pageSize: number = 10;
  searchData: any = {};

  currentLang: string = '';
  formGroup: FormGroup;

  @ViewChild('searchBar') searchBar: ElementRef;
  @ViewChild('typeSelect') typeSelect: ElementRef;

  constructor(
    public _activeModal: NgbActiveModal,
    public _apiService: ApiService,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
  ) {
    this.formGroup = this._formBuilder.group({
      searchType: [0],
      searchText: [null],
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      // order: [[2, 'asc']],
      // ordering: false,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: false,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'repoveCode', targets: 1 },
        { name: 'detail', targets: 2 },
        { name: 'rankName', targets: 3 },
        { orderable: false, targets: 4 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.customerList = [];
        self.SetLoadingState();

        let defaultSortName = 'detail';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;


        console.log(this.searchData);
        this.getCustomerList().then((res) => {
          self.customerList = res.data.resultData;

          self.SetLoadedState();
          callback({
            recordsTotal: res.data.itemCount,
            recordsFiltered: res.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  closeModal(): void {
    this._activeModal.close();
  }

  getCustomerList(): Promise<CommonResponse<Customer>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('customer', this.searchData).subscribe(
        (res: CommonResponse<Customer>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  SubmitSearch() {
    if (this.isLoading) return;

    const type = this.typeSelect.nativeElement.value;
    if (type === 'searchByCode') {
      this.searchData.repoveCode = this.searchBar.nativeElement.value;
      this.searchData.detail = '';
    } else if (type === 'searchByName') {
      this.searchData.detail = this.searchBar.nativeElement.value;
      this.searchData.repoveCode= ''
    } else {
      this.searchData.repoveCode= ''
      this.searchData.detail = '';
    }

    let Table = this.dtElements.find((item: any) => {
      return item.el.nativeElement.id == 'customerAddTable';
    });

    Table.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(() => {
        this.SetLoadedState();
      });
    });
  }

  openCustomerAddModal(customerId, customerDetail: string) {
    let isConfirm = true;
    let title = 'Confirm Add Member';
    let detail = `Are you sure to confirm Add ${customerDetail}?`;
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (isConfirm) {
        console.log('Member Added!')
      } else {
        this.closeModal();
      }
    });
  }
}
