import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ApiService } from 'app/main/service/api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ActivatedRoute } from '@angular/router';
import {
  EKYCBankHistory,
  EKYCDetail,
  EKYCImageHistory,
  EKYCStatusEnum,
  EKYCVerifyTypeEnum,
} from 'app/main/model/EKYC';
import { TranslateService } from '@ngx-translate/core';
import { FilesApiService } from 'app/main/service/files-api.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ekyc-history',
  templateUrl: './ekyc-history.component.html',
  styleUrls: ['./ekyc-history.component.scss'],
})
export class EkycHistoryComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  bankAccountList: EKYCBankHistory[] = [];
  fileDetailList: EKYCImageHistory[] = [];

  currentLang: string = '';

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  EKYCDetail: EKYCDetail;
  customerId: string = '';

  private _unsubscribeAll = new Subject();

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _fileService: FilesApiService
  ) {
    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });

    this.getEKYCBankHistory().then((res) => {
      this.bankAccountList = res.data.resultData;
    });

    this.getEKYCImageHistory().then((res) => {
      this.fileDetailList = res.data.resultData;
    });
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getEKYCBankHistory(): Promise<CommonResponse<EKYCBankHistory>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('ekyc/bank/history/customer', this.customerId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<EKYCBankHistory>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getEKYCImageHistory(): Promise<CommonResponse<EKYCImageHistory>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('ekyc/image/history/customer', this.customerId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<EKYCImageHistory>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getImage(fileId: string): string {
    return this._fileService.getImage(fileId);
  }

  getVerifyTypeLabel(typeEnum: number): string {
    switch (typeEnum) {
      case EKYCVerifyTypeEnum.BookbankDetail:
        return this._translateService.instant('EKYC.BookbankDetail');
      case EKYCVerifyTypeEnum.BookbankImage:
        return this._translateService.instant('EKYC.BookbankImage');
      case EKYCVerifyTypeEnum.IdCardImage:
        return this._translateService.instant('EKYC.IdCardImage');
      case EKYCVerifyTypeEnum.PersonalWithIdCardImage:
        return this._translateService.instant('EKYC.PersonalWithIdCardImage');

      default:
        return '-';
    }
  }
}
