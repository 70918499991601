import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventManageComponent } from './event-manage.component';
import { EventFormComponent } from './event-form/event-form.component';
import { EventAttendanceComponent } from './event-attendance/event-attendance.component';
import { CoreCommonModule } from '@core/common.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { EventEvidenceModalComponent } from './event-evidence-modal/event-evidence-modal.component';
import { AddCustomerModalComponent } from './add-customer-modal/add-customer-modal.component';

const routes: Routes = [
  {
    path: 'mlm/event-manage/view',
    component: EventManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-manage' },
  },

  {
    path: 'mlm/event-manage/create',
    component: EventFormComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-manage-create' },
  },

  {
    path: 'mlm/event-manage/edit/:id',
    component: EventFormComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-manage-edit' },
  },

  {
    path: 'mlm/event-manage/attendance/:id',
    component: EventAttendanceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-manage-attendance' },
  },
];

@NgModule({
  declarations: [
    EventManageComponent,
    EventFormComponent,
    EventAttendanceComponent,
    AddCustomerModalComponent,
    EventEvidenceModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    ReactiveFormsModule,
    ContentHeaderModule,
    NgbModule,
    TranslateModule,
    DataTablesModule,
    Ng2FlatpickrModule,
  ],
})
export class EventManageModule {}
