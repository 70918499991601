import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ApiService } from 'app/main/service/api.service';
import { rejects } from 'assert';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-system-bonus-table',
  templateUrl: './system-bonus-table.component.html',
  styleUrls: ['./system-bonus-table.component.scss'],
})
export class SystemBonusTableComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: ContentHeader;

  systemBonusDataList: SystemBonusData[] = [];
  originalSystemBonusDataList: SystemBonusData[] = [];
  logDataList: SystemBonusLog[] = [];
  selectedRowIndex: number = -1;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  pageSize: number = 10;
  page: number = 1;

  @BlockUI() blockUI: NgBlockUI;

  // systemBonus: any[] = [
  //   {
  //     condition: 'ยอดขายส่วนบุคคล 30,000+ SV ใน 1 Period',
  //     apPoint: 2,
  //     mpPoint: 2,
  //   },
  //   {
  //     condition: 'เข้าร่วม 100%',
  //     apPoint: 2,
  //     mpPoint: 2,
  //   },
  //   {
  //     condition: 'MP Bonus',
  //     apPoint: 0,
  //     mpPoint: 2,
  //   },
  // ];

  currentLang: string = '';
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.initData()
      .then(() => {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: this.pageSize,
          lengthMenu: [10, 50, 100],
          processing: true,
          paging: true,
          lengthChange: true,
          searching: false,
          ordering: false,
          info: true,
          autoWidth: false,
          responsive: true,
          deferLoading: 10,
        };
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });

    this.contentHeader = {
      headerTitle: 'BonusPlan.SystemBonus',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  initData(): Promise<void> {
    this.blockUI.start();

    let promise1 = this.getSystemBonusData().then((res) => {
      this.systemBonusDataList = res.data.resultData.map((item) => ({
        ...item,
      }));
      this.originalSystemBonusDataList = res.data.resultData.map((item) => ({
        ...item,
      }));
      this.systemBonusDataList.sort((a, b) => a.id - b.id);
      this.originalSystemBonusDataList.sort((a, b) => a.id - b.id);
    });

    let promise2 = this.getSystemBonusLogData().then((res) => {
      this.logDataList = res.data.resultData;
    });

    return Promise.all([promise1, promise2])
      .then(() => {
        this.blockUI.stop();
      })
      .catch((error) => {
        console.error('Error initializing data:', error);
        this.blockUI.stop();
      });
  }

  getSystemBonusData(): Promise<CommonResponse<SystemBonusData>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('mlm/MasterSystemBonus').subscribe(
        (res: CommonResponse<SystemBonusData>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  getSystemBonusLogData(): Promise<CommonResponse<SystemBonusLog>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('mlm/SystemLog', { direction: 1 }).subscribe(
        (res: CommonResponse<SystemBonusLog>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  saveSystemBonusData(
    id: number,
    data: {
      apPercentage: number;
      mpPercentage: number;
    }
  ): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .UpdateDataById('mlm/MasterSystemBonus', id.toString(), data)
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  saveData(index: number) {
    this.blockUI.start();
    this.saveSystemBonusData(this.systemBonusDataList[index].id, {
      apPercentage: parseFloat(
        this.systemBonusDataList[index].apPercentage.toString()
      ),
      mpPercentage: parseFloat(
        this.systemBonusDataList[index].mpPercentage.toString()
      ),
    })
      .then(() => {
        this.cancelEdit();
        this.initData();
        this.blockUI.stop();
        this._componentsService.SuccessSwal();
      })
      .catch(() => {
        this.cancelEdit();
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      });
  }

  editData(index: number) {
    this.selectedRowIndex = index;
  }

  saveRow(index: number) {
    if (this.selectedRowIndex === index) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
      });

      modalRef.componentInstance.title = this._translateService.instant(
        'Modal.ConfirmCreate'
      );
      modalRef.componentInstance.detail = this._translateService.instant(
        'Modal.AreYouSureToCreate'
      );
      modalRef.componentInstance.callBackFunc.subscribe(() => {
        this.saveData(index);
      });
      modalRef.componentInstance.cancelFunc.subscribe(() => {
        this.cancelEdit();
      });
    }
  }

  onDataChange() {}

  cancelEdit() {
    this.selectedRowIndex = -1;
    this.systemBonusDataList = this.originalSystemBonusDataList.map((item) => ({
      ...item,
    }));
  }

  deleteData(index: number) {
    this.systemBonusDataList.splice(index, 1);
  }

  getAPSum(): number {
    let result = 0;
    this.systemBonusDataList.forEach((bonus) => {
      result += parseFloat(bonus.apPercentage.toString());
    });
    return result;
  }

  getMPSum(): number {
    let result = 0;
    this.systemBonusDataList.forEach((bonus) => {
      result += parseFloat(bonus.mpPercentage.toString());
    });
    return result;
  }
}

export interface SystemBonusData {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: number;
  conditionName: string;
  apPercentage: string | number;
  mpPercentage: string | number;
}

export interface SystemBonusLog {
  isActive: boolean;
  isDelete: boolean;
  direction: number;
  sortPath: string;
  pageLength: number;
  page: number;
  status: string;
  saleInterval: number;
  fromAPPercentage: number;
  toAPPercentage: number;
  fromMPPercentage: number;
  toMPPercentage: number;
  fromPPercentage: number;
  toPPercentage: number;
}
