import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-event-manage',
  templateUrl: './event-manage.component.html',
  styleUrls: ['./event-manage.component.scss'],
})
export class EventManageComponent implements OnInit {
  contentHeader: ContentHeader;
  isLoading: boolean = false;

  // @ViewChild(DataTableDirective, { static: false })
  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  eventList: any[] = [
    {
      id: 'd3df8615-94bb-4ddb-902e-0a75be0d94b2',
      name: 'Test Event',
      startDate: '2024-03-05T17:00:00.000Z',
      endDate: '2024-03-10T07:00:00.000Z',
      detail:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel natus, inventore totam aut tempore id voluptatum ex veritatis neque repellendus obcaecati sint esse corrupti, accusantium numquam rem perferendis, consequuntur odit.',
      createDate: '2024-03-01T02:49:28.636Z',
      createBy: 'sadmin',
      isActive: true,
      isDelete: false,
      updateDate: '2024-03-02T02:49:28.636Z',
      updateBy: 'sadmin',
    },
  ];

  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('statusSelect') statusSelect: ElementRef;
  @ViewChild('typeSelect') typeSelect: ElementRef;
  @ViewChild('startDatePicker') startDatePicker: ElementRef;
  @ViewChild('endDatePicker') endDatePicker: ElementRef;

  currentLang: string = '';
  searchData: any = {};
  formGroup: FormGroup;

  pageSize: number = 10;

  constructor(
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private apiService: ApiService
  ) {
    this.formGroup = this._formBuilder.group({
      startDate: [null],
      endDate: [null],
    });
  }

  public dateOptions: FlatpickrOptions = {
    // enableTime: true,
    time_24hr: true,
    timeFormat: 'H:i:ss',
    dateFormat: 'd/m/Y',
    noCalendar: false,
    // altInput: true,
    // altInputClass: 'form-control flat-picker flatpickr-input',
    disabled: true,
    allowInput: true,
    clickOpens: true,
  };

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'Event Manage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      // order: [[2, 'asc']],
      // serverSide: true,
      ordering: false,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'InvoiceCode', targets: 1 },
        { name: 'Price', targets: 2 },
        { name: 'Status', targets: 3 },
        { name: 'IsActive', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        // this.eventList = [];
        self.SetLoadingState();

        let defaultSortName = 'BusinessName';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        // this.searchData.returnState = this.searchByStatus;

        this.searchData.isReturn = true;

        // this.searchData.returnType = this.returnType
        // ? this.returnType
        // : 0;

        console.log(this.searchData);
        this.getEventList().then((res) => {
          self.eventList = res.data.resultData;

          self.SetLoadedState();
          callback({
            recordsTotal: res.data.itemCount,
            recordsFiltered: res.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ResetSearch() {
    if (this.isLoading) return;

    //inputsearch reset
    this.searchBar.nativeElement.value = '';
    //activestatus reset
    this.statusSelect.nativeElement.value = 'all';
  }

  SubmitSearch() {
    // if (this.isLoading) return;
    let params: any = {};

    params.name = this.searchBar.nativeElement.value;

    const status = this.statusSelect.nativeElement.value;
    if (status === 'active') {
      params.isActive = true;
    } else if (status === 'inactive') {
      params.isActive = false;
    } else {
      params.isActive = '';
    }

    const type = this.typeSelect.nativeElement.value;
    if (type === 'type1') {
      params.type = 'type1';
    } else if (type === 'type2') {
      params.type = 'type2';
    } else {
      params.type = '';
    }

    params.startDate = this.formGroup.controls['startDate'].value
      ? new Date(this.formGroup.controls['startDate'].value).toISOString()
      : null;
    params.endDate = this.formGroup.controls['endDate'].value
      ? new Date(this.formGroup.controls['endDate'].value).toISOString()
      : null;

    console.log(params);

    // let Table = this.dtElements.find((item: any) => {
    //   return item.el.nativeElement.id == 'eventManageTable';
    // });

    // Table.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.ajax.reload(() => {
    //     this.SetLoadedState();
    //   });
    // });
  }

  getEventList(): Promise<CommonResponse<any>> {
    return new Promise((resolve, rejects) => {
      this.apiService.GetAllData('', this.searchData).subscribe(
        (res: CommonResponse<any>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  openDeleteContentModal() {
    
  }
}
