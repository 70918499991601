import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';
import { PaymentAction } from 'app/main/model/PaymentAction';
import { ModalManagePaymentComponent } from '../modal-manage-payment/modal-manage-payment.component';
import { BankAccount } from 'app/main/model/BankAccount';

@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalPaymentComponent implements OnInit {
  @Input() public modalConfig: NgbModalConfig;
  @Input() isConfirm: boolean;
  @Input() title: string;
  @Input() objData: any;
  @Input() netData: any;
  @Input() detail: string;
  @Input() confirmUrl: string;
  @Input() docCodeColumn: string;
  @Input() notSplitPay: boolean;
  @Input() isGoodsReceive: boolean = true;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();

  selectedPayment: any = null;
  selectedBankAccount: any = null;

  isSubmit: boolean = false;

  balance: number = 0.0;
  isCheckPayment: boolean = true;
  isCheckWhtTax: boolean = false;
  payForm: FormGroup;
  WthTax: number = 0.0;
  payAmount: number = 0.0;
  salesWHT: number;
  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  paymentTypeApiPath: string;

  loadingPaymentList: boolean = false;
  isJoinIV_TX_RE: boolean = false;
  isValidValue: boolean = true;
  isUseWHT: boolean = environment.transaction.isUseWht;

  isMaxTodayDate: boolean = true;

  currentDate: any = new Date().toISOString();
  selectedDate: any;

  DefaultDateOption: FlatpickrOptions = {
    defaultDate: this.currentDate,
    altInput: true,
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
  };

  chequeDateOption: FlatpickrOptions = {
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
    enableTime: false,
  };

  maxTodayDateOption: FlatpickrOptions = {
    defaultDate: this.currentDate,
    altInput: true,
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
    maxDate: this.currentDate,
  };

  paymentList: any[];
  bankAccountList: BankAccount[];

  isLoadingBankAccount: boolean = false;

  isTransfer: boolean = false;
  isCreditDebitCard: boolean = false;
  isCheque: boolean = false;
  isOther: boolean = false;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _globalFuncService: GlobalFuncService,
    private formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _calculateService: CalculateFuncService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _translateService: TranslateService
  ) {
    this.payForm = this.formBuilder.group({
      paymentDate: [null, []],
      selectedPayment: 0,
      selectedBankAccount: null,
      payAmount: 0.0,
      whtRate: 0.0,
      salesWHT: 0.0,
      remarks: '',
    });
    this.paymentTypeApiPath = this._documentService.paymentType.ApiPath;
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
    console.log(this.netData);
  }

  closeModal(): void {
    this.activeModal.close();
  }

  callBack(): void {
    console.log(this.payAmount);
    console.log(this.salesWHT);

    this.isSubmit = true;

    if (this.selectedBankAccount == null && this.isTransfer) {
      console.error('Please select or fill all input');
    } else {
      this.payForm.patchValue({
        payAmount: this.payAmount,
        salesWHT: this.salesWHT,
        bankAccountId: this.selectedBankAccount,
        paymentTypeId: this.selectedPayment,
        paymentDate: this.selectedDate.toISOString(),
      });

      console.log('payForm value : ', this.payForm.value);
      this.callBackFunc.emit(this.payForm.value);
      this.closeModal();
    }
  }

  removeComma(element) {
    let formattedAmount: string;
    formattedAmount = element.target.value;
    element.target.value = formattedAmount.replace(/,/g, '');
  }

  convertNumbertoMoneyFormat(
    value: number,
    showFourDigit: boolean = false // default = false
  ): string {
    return this._globalFuncService.FormatToMoney(value, showFourDigit);
  }

  convertMoneytoNumber(value: string): number {
    return this._globalFuncService.MoneyFormatToNumber(value);
  }

  togglePayment(): void {
    this.isCheckPayment = !this.isCheckPayment;
  }

  toggleWhtTax(): void {
    this.isCheckWhtTax = !this.isCheckWhtTax;
  }

  clearWhtRate(): void {
    this.WthTax = 0.0;
  }

  getSumTotal(): number {
    return !isNaN(this.payAmount) ? this.payAmount : 0.0;
  }

  calculateBalance() {
    const inputField = document.getElementById('payAmount') as HTMLInputElement;
    const inputWHTField = document.getElementById(
      'salesWHT'
    ) as HTMLInputElement;

    const value = this.convertMoneytoNumber(inputField.value);
    const valueWHT = this.convertMoneytoNumber(inputWHTField.value);

    this.payAmount = this.convertMoneytoNumber(
      this.convertNumbertoMoneyFormat(value, true)
    );
    this.salesWHT = this.convertMoneytoNumber(
      this.convertNumbertoMoneyFormat(valueWHT, true)
    );

    this.balance =
      this.netData.preWHTAmountTotal - (this.payAmount + this.WthTax);

    this.checkValidValue();
  }

  checkValidValue() {
    if (
      this.balance <= this.netData.preWHTAmountTotal &&
      this.balance >= 0 &&
      this.getSumTotal() <= this.netData.preWHTAmountTotal
    ) {
      this.isValidValue = true;
    } else {
      this.isValidValue = false;
    }
  }

  get f() {
    return this.payForm.controls;
  }

  triggerRefreshData(initDocData: any) {
    this.refreshData.emit();
  }

  getBankAccount(id?: number) {
    this.isLoadingBankAccount = true;

    const params = {
      isActive: true,
      isDelete: false,
    };
    const apiPath = 'BankAccount';

    this._apiService.GetAllData(apiPath, params).subscribe((res) => {
      this.bankAccountList = res.data.resultData;
      
      this.bankAccountList.sort((a, b) =>
        a.isUseForDocument === b.isUseForDocument
          ? 0
          : a.isUseForDocument
          ? -1
          : 1
      );
      console.log('bankAccountList : ', this.bankAccountList);

      if (id) {
        let matchingBankAccount = this.bankAccountList.find(
          (item) => item.id == id
        );
        console.log('matchingBankAccount : ', matchingBankAccount);
        this.selectedBankAccount = matchingBankAccount
          ? matchingBankAccount.id
          : null;
      } else {
        this.selectedBankAccount = this.bankAccountList[0].id;
      }

      this.isLoadingBankAccount = false;
    });
  }

  getPayment() {
    this.loadingPaymentList = true;

    let params = {
      isActive: true,
      isDelete: false,
    };
    // this._apiService
    //   .GetAllData(this.paymentTypeApiPath, params)
    //   .subscribe((res) => {
    //     this.paymentList = res.data.resultData.map(
    //       (payment: { id: any; name: string }) => ({
    //         id: payment.id,
    //         name: this._translateServeice.instant('Payment.' + payment.name),
    //       })
    //     );

    this.paymentList = Object.values(PaymentMethodList).map(
      (payment: { id: any; name: string }) => ({
        id: payment.id,
        name: this._translateService.instant('Payment.' + payment.name),
      })
    );

    if (this.paymentList && this.paymentList.length > 0) {
      this.selectedPayment = this.paymentList[0].id;
      this.isMaxTodayDate = true;
    }

    this.loadingPaymentList = false;
    console.log('paymentList : ', this.paymentList);
    console.log('selectedPayment : ', this.selectedPayment);
    // });
  }

  onDateChange(date: Date[]) {
    let tempDate = this.payForm.get('paymentDate').value;
    this.selectedDate = new Date(tempDate);
    console.log(this.selectedDate);
    console.log(this.selectedDate.toISOString());
  }

  onPaymentMethodChange() {
    switch (this.selectedPayment.toLowerCase()) {
      case PaymentMethodList.Transfer.id.toLowerCase():
        this.getBankAccount();
        this.setFlags(true, false, false, false);
        break;
      // case PaymentMethodList.CreditDebit.id.toLowerCase():
      //   this.setFlags(false, true, false, false);
      //   break;
      // case PaymentMethodList.Cheque.id.toLowerCase():
      //   this.setFlags(false, false, true, false);
      //   this.isMaxTodayDate = false;
      //   break;
      // case PaymentMethodList.Other.id.toLowerCase():
      //   this.setFlags(false, false, false, true);
      //   break;
      default:
        this.isMaxTodayDate = true;
        this.setFlags(false, false, false, false);
        this.payForm.get('paymentDate').patchValue(new Date().toISOString());
        break;
    }

    console.log('selectedPayment : ', this.selectedPayment);
    console.log('isMaxTodayDate : ', this.isMaxTodayDate);
  }

  setFlags(
    transfer: boolean,
    creditDebit: boolean,
    cheque: boolean,
    other: boolean
  ) {
    this.isTransfer = transfer;
    this.isCreditDebitCard = creditDebit;
    this.isCheque = cheque;
    this.isOther = other;
  }

  onPaymentCanCreateChange(event: string) {
    if (event === PaymentAction.BankAccount.Create) {
      console.log('Open Modal Create Bank Account');
      this.openManagePaymentModal(event);
    } else {
      // Handle the selection of a regular bank account option.
      // The selected bank account ID is available in this.selectedBankAccount.
    }
  }

  openManagePaymentModal(event: string) {
    const modalRef = this._modalService.open(ModalManagePaymentComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.actionPayment = event;
    modalRef.componentInstance.setMode = false;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.selectedBankAccount = null;
    });
    modalRef.componentInstance.refreshData.subscribe((res) => {
      this.getBankAccount(res[0]);
    });
  }

  onFormatAmountBlur() {
    const inputField = document.getElementById('payAmount') as HTMLInputElement;
    const inputWHTField = document.getElementById(
      'salesWHT'
    ) as HTMLInputElement;

    const value = this.convertMoneytoNumber(inputField.value);
    const valueWHT = this.convertMoneytoNumber(inputWHTField.value);

    inputField.value = value
      ? this.convertNumbertoMoneyFormat(value, true)
      : '0.00';
    inputWHTField.value = valueWHT
      ? this.convertNumbertoMoneyFormat(valueWHT, true)
      : '0.00';

    this.payAmount = this.convertMoneytoNumber(
      this.convertNumbertoMoneyFormat(value, true)
    );
    this.salesWHT = this.convertMoneytoNumber(
      this.convertNumbertoMoneyFormat(valueWHT, true)
    );

    this.calculateBalance();
  }

  calculateAmountwithTaxRate() {
    const whtRate = this.payForm.get('whtRate').value;
    console.log(
      this._calculateService.calculateWHT(
        this.convertMoneytoNumber(this.payForm.get('payAmount').value),
        whtRate
      )
    );
    this.WthTax = this._calculateService.calculateWHT(
      this.convertMoneytoNumber(this.payForm.get('payAmount').value),
      whtRate
    );
  }

  ngOnInit(): void {
    this.getPayment();
    console.log(this.objData);
    this.payForm = this.formBuilder.group({
      docId: this.objData.id,
      paymentTypeId: [this.selectedPayment, Validators.required],
      bankAccountId: [this.selectedBankAccount, Validators.required],
      paymentDate: [null, []],
      payAmount: [
        this.convertNumbertoMoneyFormat(
          this.netData.preWHTAmountTotal ? this.netData.preWHTAmountTotal : 0.0
        ),
      ],
      salesWHT: [
        this.convertNumbertoMoneyFormat(
          this.netData.salesWHT ? this.netData.salesWHT : 0.0
        ),
      ],
      remarks: [],
    });
    this.payAmount = this.convertMoneytoNumber(
      this.payForm.get('payAmount').value
    );
    this.salesWHT = this.convertMoneytoNumber(
      this.payForm.get('salesWHT').value
    );

    this.checkValidValue();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log(this.payForm);
  }

  ngOnDestroy(): void {}
}
