import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BankList } from 'app/main/model/BankAccount';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EkycFormModalComponent } from './ekyc-form-modal/ekyc-form-modal.component';

import { CommonResponse } from 'app/main/model/CommonResponse';

import { TranslateService } from '@ngx-translate/core';
import { EKYCDetail, EKYCStatusEnum } from 'app/main/model/EKYC';
import { takeUntil } from 'rxjs/operators';

declare var GLightbox: any;
@Component({
  selector: 'app-ekyc-detail',
  templateUrl: './ekyc-detail.component.html',
  styleUrls: ['./ekyc-detail.component.scss'],
})
export class EkycDetailComponent implements OnInit, OnDestroy {
  pidImage: string;
  pidWithPersonImage: string;
  bookBankImage: string;

  pidImageFile: File;
  pidWithPersonImageFile: File;
  bookBankImageFile: File;

  og_pidImage: string;
  og_pidWithPersonImage: string;
  og_bookBankImage: string;

  isPidImageInvalid: boolean = false;
  isPidWithPersonImageInvalid: boolean = false;

  isPidImageChanged: boolean = false;
  isPidWithPersonImageChanged: boolean = false;
  isBookBankImageChanged: boolean = false;

  previewIMG: string;
  previewIMG_file: File;

  maxFileSize: number = environment.limitFileSize.maxImageSizeInBytes;
  activeModal: any;

  EKYCDetail: EKYCDetail = new EKYCDetail();

  bankAccountList: any[] = [];
  isLoading: boolean = false;
  isSubmit: boolean = false;
  isDataChanged: boolean = false;

  public isEditing: Observable<boolean>;
  public isDataSaved: Observable<boolean>;

  private isEditingSubject: BehaviorSubject<boolean>;
  private isDataSavedSubject: BehaviorSubject<boolean>;

  // @Output() setEditing: EventEmitter<any> = new EventEmitter();

  page: number = 1;
  pageSize: number = 10;
  searchData: any = {};

  bankAccountForm: FormGroup;
  bankNameList: { id: number; name: string }[] = [];
  originalData: {
    bankId: number;
    accountNumber: string;
    accountName: string;
  };

  selectedFileEnum: string;

  customerId: string;

  bankaccountname: string;
  bankaccountnumber: string;

  bank: any = {
    id: 4,
  };

  stateEnum = EKYCStatusEnum;

  private _unsubscribeAll = new Subject();

  constructor(
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });

    this.bankNameList = BankList;

    this.getEKYCDetail().then((res) => {
      this.EKYCDetail = res.data.resultData[0];
      // if (this.EKYCDetail) {
      // this.getEKYCImageFile('guid').then((blob) => {
      //   let file = new File([blob], "filename")
      //   this.pidImageFile = file;
      //   this.pidWithPersonImageFile = file;
      //   this.bookBankImageFile = file;
      // });
      // }
    });
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const ekycLightBox = new GLightbox({
        selector: '.glightbox',
        closeOnOutsideClick: true,
        width: 'auto',
        height: 'auto',
      });
    }, 300);
  }

  resetTab() {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openEKYCFormModal() {
    const modalRef = this._modalService.open(EkycFormModalComponent, {
      centered: true,
      size: 'xl',
    });

    modalRef.componentInstance.EKYCDetail = this.EKYCDetail;
    modalRef.componentInstance.callBackFunc.subscribe(() => {});
  }

  getBankImg(bankId: number) {
    if (bankId) {
      return this._globalFuncService.getBankImg(bankId.toString());
    }
  }

  getBankName(bankId: number) {
    if (bankId) {
      return this._globalFuncService.getBankName(bankId);
    }
  }

  viewImage(res) {
    const blob = new Blob([res], { type: 'image/*' });
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    window.open(img.src, '_blank');
  }

  getEKYCDetail(): Promise<CommonResponse<EKYCDetail>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('ekyc/customer', this.customerId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<EKYCDetail>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getEKYCImageFile(guid: string): Promise<Blob> {
    return new Promise((resolve, rejects) => {
      this._fileService.GetFile(guid).subscribe(
        (res: Blob) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  getStatusLabel(state: number): any {
    switch (state) {
      case this.stateEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case this.stateEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case this.stateEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }

  onFileStatusChange(fileEnum: string, value: string) {
    if (fileEnum == 'pidImage') {
      this.EKYCDetail.verifyIdCardImageStatusEnum = parseInt(value);
    } else if (fileEnum == 'pidWithPersonImage') {
      this.EKYCDetail.verifyPersonalWithIdCardImageStatusEnum = parseInt(value);
    } else if (fileEnum == 'bookBankImage') {
      this.EKYCDetail.verifyBookbankImageStatusEnum = parseInt(value);
    }
  }

  onBankStatusChange(value) {
    this.EKYCDetail.verifyBookbankDetailStatusEnum = value;
  }
}
