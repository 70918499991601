import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
declare var GLightbox: any;

@Component({
  selector: 'app-event-evidence-modal',
  templateUrl: './event-evidence-modal.component.html',
  styleUrls: ['./event-evidence-modal.component.scss']
})
export class EventEvidenceModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  @Output() cancelFunc: EventEmitter<any> = new EventEmitter();
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Input() imageEvidenceList: { file: string; ordinal: number }[] = [];
  @Input() customerData: any

  @BlockUI()  blockUI: NgBlockUI

  ngOnInit(): void {
    
    console.log(this.customerData)
  }
  ngAfterViewInit() {
    this.blockUI.start()
    const lightbox = GLightbox({
      selector: '.glightbox',
      closeOnOutsideClick: true,

    });
    this.blockUI.stop()

  }


  closeModal() {
    this.cancelFunc.emit()
    this.activeModal.close()
    

  }

  callBack() {
    
  }
}
