<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <div class="card event-attendance">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-center general-title">
                            <div>
                                <h5 class="">รายละเอียด</h5>
                            </div>
                            <div>
                                <a [routerLink]="['/mlm/event-manage/edit/' + event.id]" class="btn btn-sm btn-primary">
                                    <i data-feather="edit" class="mr-1"></i>แก้ไข
                                </a>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col">
                                <label for="name">ชื่อ Event</label>
                                <p>{{ event.name }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <label for="startDate">วันที่เรื่ม</label>
                                <p *ngIf="currentLang == 'th'">
                                    {{ event.startDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</p>
                                <p *ngIf="currentLang == 'en'">
                                    {{ event.startDate | date : 'dd MMM YYYY HH:mm' }}
                                </p>
                            </div>
                            <div class="col">
                                <label for="endDate">วันที่สิ้นสุด</label>
                                <p *ngIf="currentLang == 'th'">
                                    {{ event.endDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</p>
                                <p *ngIf="currentLang == 'en'">
                                    {{ event.endDate | date : 'dd MMM YYYY HH:mm' }}
                                </p>
                            </div>
                        </div>

                        <hr>

                        <div class="row">
                            <div class="col">
                                <label for="detail">ผู้ที่มีสิทธิ์เข้าร่วม</label>
                                <p>Provider, Active Provider, Master Provider</p>
                            </div>


                        </div>

                        <div class="row">
                            <div class="col">
                                <label for="detail">วันที่สร้างอีเวนท์</label>
                                <p *ngIf="currentLang == 'th'">
                                    {{ event.createDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</p>
                                <p *ngIf="currentLang == 'en'">
                                    {{ event.createDate | date : 'dd MMM YYYY HH:mm' }}
                                </p>
                            </div>

                            <div class="col">
                                <label for="detail">สร้างโดย</label>
                                <p>{{ event.createBy }}</p>
                            </div>
                        </div>

                        <hr class="text-primary">


                        <div class="row">
                            <div class="col">
                                <label for="detail">รายละเอียด</label>
                                <p>{{ event.detail }}</p>
                            </div>
                        </div>

                        <hr>



                        <h5 class="general-title">รางวัลการเข้าร่วม (Event Bonus)</h5>
                        <div class="row">
                            <div class="col">
                                <table class="table">
                                    <thead>
                                        <tr class="text-center">
                                            <th>Provider</th>
                                            <th>Active Provider</th>
                                            <th>Master Provider</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>99%</td>
                                            <td>20%</td>
                                            <td>100%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col">

                        <div class="d-flex justify-content-between align-items-center mb-1 general-title">
                            <div>
                                <h5 class="">รายชื่อผู้เข้าร่วม</h5>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-primary" (click)="openAddCustomerModal()">
                                    <i data-feather="plus" class="mr-1"></i>เพื่ม
                                </button>
                            </div>
                        </div>
                        <table datatable [dtOptions]="dtOptions" class="table">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Customer</th>
                                    <th>Rank</th>
                                    <th width="15%" class="text-center">Evidence</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="customerList.length < 1 && !isLoading">
                                    <td colspan="100%" class="text-center">
                                        <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                                    </td>
                                </tr>

                                <tr *ngFor="let customer of customerList; index as ind">
                                    <td class="text-center">{{ ind + 1 }}</td>
                                    <td>
                                        <a [routerLink]="['/customer/detail/' + customer.id]" target="_blank"
                                            ngbTooltip="{{ customer.detail }}">
                                            <span class="text-primary text-truncate"> {{ customer.detail }}</span>
                                        </a>
                                    </td>
                                    <td>
                                        <p>{{ customer.rankName }}</p>
                                    </td>
                                    <td class="text-center">
                                        <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                                            (click)="openCustomerEvidence(customer.id)"><i data-feather="eye"
                                                class="text-success"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>