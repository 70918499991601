<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel
      [forCompanentName]="componentName"
      [pathUrl]="pathUrl + '/create'"
      [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate"
      [isShowSortByGroup]="true"
      [isShowCategory]="true"
      [loadAllCategoryData]="true"
      (listView)="listView()"
      (gridView)="gridView()"
      (SetLoading)="SetLoadingState()"
      (exportXLSX)="exportXLSX()"
      (callDataFromAPI)="initData($event)"
      [isLoading]="isLoading"
    ></app-search-panel>

    <section>
      <div [ngClass]="{ 'd-none': isGridView }">
        <app-content-list
          [apiPath]="apiPath"
          [searchData]="searchData"
          [mainPathUrl]="pathUrl + '/detail'"
          [editPathUrl]="pathUrl + '/edit'"
          [page]="page"
          [pageSize]="pageSize"
          (deleteFunc)="deleteContent($event)"
          (isActiveFunc)="setActiveContent($event)"
          [isGoEditPage]="isGoEditPage"
          (SetLoadingStateFunc)="SetLoadingStateFunc($event)"
        ></app-content-list>
      </div>
    </section>
  </div>
</div>
