<block-ui></block-ui>

<div class="card add-bill">
  <div class="card-body">
    <form [formGroup]="quotationForm" id="add-bill-form">
      <div class="d-flex justify-content-center">
        <div>
          <h4>{{ docTitle | translate }}</h4>
        </div>

        <!-- <div class="scopetest">
          <div class="loading-ng-bar">

          </div>
        </div> -->
      </div>
      <hr />

      <div class="d-block">
        <div class="row d-flex justify-content-between">
          <div class="col-6 form-group">
            <div class="scope-custom" [ngClass]="{
                'is-invalid error':
                  (isSubmit && f.customerId?.invalid) ||
                  (f.customerId?.invalid &&
                    (f.customerId?.dirty || f.customerId?.touched)) ||
                  (f.customerId?.value == '' &&
                    (f.customerId?.dirty || f.customerId?.touched))
              }">
              <label for="">{{
                "SearchPanel.Document.CustomerName" | translate
                }}</label>
              <ng-container *ngIf="!isLoadingCustomerList">
                <ng-select formControlName="customerId" class="custom-select" placeholder="-- {{
                    'FormQuotation.SelectCustomer' | translate
                  }} --" [loading]="isLoadingCustomerList" [clearable]="false" (change)="onSelectedCustomer($event)">
                  <ng-option *ngFor="let customer of customerList" [value]="customer.id"
                    [selected]="customer.id === data.customerId">{{ customer.repoveCode }} -
                    {{ customer.islegalEntity ? customer.businessName : customer.customerFirstName + " " + customer.customerLastName }}
                    <span *ngIf="customer.islegalEntity">
                      (
                      {{
                      customer.isHeadBranch
                      ? ("Customer.headOffice" | translate)
                      : customer.branchNumber.toString().padStart(5, "0") +
                      ( customer.branchName
                      ? " (" + customer.branchName + ")"
                      : "")
                      }}
                      )
                    </span>
                  </ng-option>
                </ng-select>
              </ng-container>
              <div *ngIf="isLoadingCustomerList" style="width: 200px; text-align: center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 form-group">
            <div class="scope-custom" [ngClass]="{ 'is-invalid error': isDateError }">
              <label for="">{{ "FormQuotation.CreateDate" | translate }}
              </label>
              <ng2-flatpickr class="date-form-control" [class.invalid-date]="invalidDate"
                [setDate]="createDateOptions.defaultDate" [config]="createDateOptions"
                formControlName="documentCreateDate" (change)="onDateFromChange($event)" #createDate
                *ngIf="isDateChange"></ng2-flatpickr>

              <div *ngIf="!isDateChange" style="padding: 10px">
                {{ createDateOptions.defaultDate | date : "d/M/yyyy" }}
              </div>
            </div>
          </div>

          <div class="col-3 form-group" *ngIf="!isCreateGR && isUseValidDate">
            <div class="scope-custom" [ngClass]="{ 'is-invalid error': isDateError }">
              <label for="">{{ "FormQuotation.ExprieDate" | translate }}</label>
              <ng2-flatpickr class="date-form-control" [class.invalid-date]="invalidDate"
                [setDate]="expiredDateOptions.defaultDate" [config]="expiredDateOptions"
                formControlName="documentExpireDate" *ngIf="isDateChange" #expriedDate></ng2-flatpickr>

              <div *ngIf="!isDateChange" style="padding: 10px">
                {{ expiredDateOptions.defaultDate | date : "d/M/yyyy" }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4 form-group" *ngIf="!isCreditDebitMode">
            <div class="scope-custom">
              <label for="">{{ "FormQuotation.DocCode" | translate }}</label>
              <input type="text" class="form-control" [(ngModel)]="docCode" [ngModelOptions]="{ standalone: true }"
                disabled id="docCode" />
            </div>
          </div>

          <div class="col-4 form-group" *ngIf="!isCreditDebitMode && !isCreateGR">
            <div class="scope-custom">
              <label for="">{{ "FormQuotation.DocRef" | translate }}</label>
              <input type="text" class="form-control" disabled id="docRef" />
            </div>
          </div>

          <div class="col-4 form-group" *ngIf="isCreditDebitMode">
            <div class="scope-custom">
              <label for="">{{ "FormQuotation.DocCode" | translate }}</label>
              <!-- <input type="text" class="form-control" disabled id="docRef" /> -->
              <input type="text" class="form-control" disabled [value]="documentCode" />
            </div>
          </div>

          <div class="col-4 form-group" *ngIf="isCreditDebitMode">
            <div class="scope-custom">
              <label for="">{{ "FormQuotation.DocRef" | translate }}</label>
              <input type="text" class="form-control" disabled id="docCode" />
            </div>
          </div>

          <div class="col-4 form-group" *ngIf="isCreateGR">
            <div class="scope-custom">
              <label for="">{{ "FormQuotation.DocRef" | translate }}</label>
              <input type="text" class="form-control" [value]="documentRef" id="docRef" disabled />
            </div>
          </div>

          <div class="col-4 form-group" *ngIf="!isCreditDebitMode">
            <div class="scope-custom">
              <label for="">{{
                "FormQuotation.Price.Title" | translate
                }}</label>
              <select class="custom-select" style="color: #3498db" formControlName="isExcludeTax"
                (change)="onSelectedTaxMode($event)">
                <option [value]="null" disabled>
                  -- {{ "FormQuotation.Price.Select" | translate }} --
                </option>
                <option [value]="false" [selected]="data.isExcludeTax === false">
                  {{ "FormQuotation.Price.Include" | translate }}
                </option>
                <option [value]="true" [selected]="data.isExcludeTax === true">
                  {{ "FormQuotation.Price.Exclude" | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-4 form-group" *ngIf="isCreditDebitMode">
            <div class="scope-custom">
              <label for="">{{
                "FormQuotation.Price.Title" | translate
                }}</label>
              <a class="typetax" formControlName="isExcludeTax">
                <span>
                  {{
                  data.isExcludeTax
                  ? ("FormQuotation.Price.Exclude" | translate)
                  : ("FormQuotation.Price.Include" | translate)
                  }}
                </span>
              </a>
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div [ngClass]="{
                'col-6 form-group':
                  !isCreateInvoice && !isCreditDebitMode && !isCreateGR,
                'col-5 form-group':
                  isCreateInvoice || isCreditDebitMode || isCreateGR
              }">
              <div class="scope-custom" *ngIf="!isCreateInvoice && !isCreditDebitMode && !isCreateGR">
                <label for="">{{
                  isBuyerDoc
                  ? ("FormQuotation.AddressReceive" | translate)
                  : ("FormQuotation.AddressShipping" | translate)
                  }}</label>

                <ng-container *ngIf="!isLoadingShippingAddress">
                  <ng-select formControlName="shippingAddress" class="custom-select address-select"
                    [items]="shippingAddressList" (change)="onSelectedShippingAddress()" bindLabel="detail"
                    [searchable]="false" [clearable]="false" [loading]="isLoadingShippingAddress"
                    ngbTooltip="{{ shippingAddressDetail }}" tooltipClass="custom-tooltip" [placeholder]="
                      isBuyerDoc
                        ? ('FormQuotation.SelectReceiveAddress' | translate)
                        : ('FormQuotation.SelectShippingAddress' | translate)
                    ">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="custom-option-body">
                        <div class="d-flex justify-content-between align-items-start">
                          <p class="custom-option-title custom-option-text">
                            {{ item.detail }} | {{ item.phone }}
                          </p>
                          <div class="badge badge-secondary" *ngIf="item.isDefault">
                            {{ "Address.isDefault" | translate }}
                          </div>
                        </div>
                        <p class="card-text custom-option-text">
                          {{ GetAddressTemplate(item) }}
                        </p>
                      </div>
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item">
                      {{ GetAddressTemplate(item) }}
                    </ng-template>
                  </ng-select>
                </ng-container>
                <div *ngIf="isLoadingShippingAddress" style="width: 200px; text-align: center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="scope-custom" *ngIf="isCreateInvoice || isCreditDebitMode || isCreateGR">
                <label for="">{{
                  isBuyerDoc
                  ? ("FormQuotation.AddressReceive" | translate)
                  : ("FormQuotation.AddressShipping" | translate)
                  }}</label>
                <p *ngIf="!isEditShippingAddress" class="custom-show-select" ngbTooltip="{{ shippingAddressDetail }}"
                  tooltipClass="custom-tooltip">
                  {{ shippingAddressDetail }}
                </p>
                <ng-container *ngIf="isEditShippingAddress">
                  <ng-select formControlName="shippingAddress" class="custom-select address-select"
                    [items]="shippingAddressList" (change)="onSelectedShippingAddress()" bindLabel="detail"
                    [searchable]="false" [clearable]="false" [loading]="isLoadingShippingAddress"
                    ngbTooltip="{{ shippingAddressDetail }}" tooltipClass="custom-tooltip" [placeholder]="
                      isBuyerDoc
                        ? ('FormQuotation.SelectReceiveAddress' | translate)
                        : ('FormQuotation.SelectShippingAddress' | translate)
                    ">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="custom-option-body">
                        <div class="d-flex justify-content-between align-items-start">
                          <p class="custom-option-title custom-option-text">
                            {{ item.detail }} | {{ item.phone }}
                          </p>
                          <div class="badge badge-secondary" *ngIf="item.isDefault">
                            {{ "Address.isDefault" | translate }}
                          </div>
                        </div>
                        <p class="card-text custom-option-text">
                          {{ GetAddressTemplate(item) }}
                        </p>
                      </div>
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item">
                      {{ GetAddressTemplate(item) }}
                    </ng-template>
                  </ng-select>
                </ng-container>
              </div>
            </div>
            <div class="col-1 form-group" style="padding-top: 10px"
              *ngIf="isCreateInvoice || isCreditDebitMode || isCreateGR">
              <a *ngIf="!isEditShippingAddress" class="btn btn-outline-primary text-primary"
                (click)="editShippingAddress()" ngbTooltip="{{
                  isBuyerDoc
                    ? 'Address.editReceiveShippingAddress'
                    : ('Address.editSendShippingAddress' | translate)
                }}" tooltipClass="custom-tooltip">
                <i data-feather="edit"></i>
              </a>
              <a *ngIf="isEditShippingAddress" class="btn btn-outline-primary text-primary"
                (click)="cancelEditShippingAddress()" ngbTooltip="{{ 'Address.cancelEditAddress' | translate }}"
                tooltipClass="custom-tooltip">
                <i data-feather="x-circle"></i>
              </a>
            </div>

            <div class="col-6 form-group">
              <div class="scope-custom" *ngIf="!isCreateInvoice && !isCreditDebitMode && !isCreateGR">
                <label for="">{{
                  isBuyerDoc
                  ? ("FormQuotation.AddressIssueBilling" | translate)
                  : ("FormQuotation.AddressBilling" | translate)
                  }}</label>

                <ng-container *ngIf="!isLoadingBillingAddress">
                  <ng-select formControlName="taxInvoiceAddress" class="custom-select address-select"
                    [items]="customerAddressList" (change)="onSelectedBillingAddress()" bindLabel="detail"
                    [searchable]="false" [clearable]="false" [loading]="isLoadingBillingAddress"
                    ngbTooltip="{{ taxInvoiceAddressDetail }}" tooltipClass="custom-tooltip" [placeholder]="
                      isBuyerDoc
                        ? ('FormQuotation.SelectIssueBillingAddress'
                          | translate)
                        : ('FormQuotation.SelectBillingAddress' | translate)
                    ">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="custom-option-body">
                        <div class="d-flex justify-content-between align-items-start">
                          <p class="custom-option-title custom-option-text">
                            <u>{{ item.detail }} | {{ item.phone }}</u>
                          </p>
                          <div class="badge badge-primary" *ngIf="item.isDefault">
                            {{ "Address.isDefault" | translate }}
                          </div>
                        </div>
                        <p class="card-text custom-option-text">
                          {{ GetAddressTemplate(item) }}
                        </p>
                      </div>
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item">
                      {{ GetAddressTemplate(item) }}
                    </ng-template>
                  </ng-select>
                </ng-container>
                <div *ngIf="isLoadingShippingAddress" style="width: 200px; text-align: center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="scope-custom" *ngIf="isCreateInvoice || isCreditDebitMode || isCreateGR">
                <label for="">{{
                  isBuyerDoc
                  ? ("FormQuotation.AddressIssueBilling" | translate)
                  : ("FormQuotation.AddressBilling" | translate)
                  }}</label>
                <p class="custom-show-select" ngbTooltip="{{ taxInvoiceAddressDetail }}" tooltipClass="custom-tooltip">
                  {{ taxInvoiceAddressDetail }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3 form-group" *ngIf="isSellerDoc">
            <div class="scope-custom">
              <label for="saleRef">{{
                "FormQuotation.SaleRef" | translate
                }}</label>
              <input type="text" class="form-control" value="0000000001" id="saleRef" disabled />
            </div>
          </div>
          <ng-container *ngIf="isCreditNote">
            <div class="col-3 form-group">
              <div class="scope-custom">
                <label for="">{{
                  "FormQuotation.RecieveMoreProduct.Label" | translate
                  }}</label>
                <ng-container>
                  <ng-select formControlName="isProductReceived" class="custom-select" [clearable]="false"
                    (change)="onSelectProductReceive($event)">
                    <ng-option [value]="false" selected>
                      {{
                      "FormQuotation.RecieveMoreProduct.Option1" | translate
                      }}
                    </ng-option>
                    <ng-option [value]="true">
                      {{
                      "FormQuotation.RecieveMoreProduct.Option2" | translate
                      }}
                    </ng-option>
                  </ng-select>
                </ng-container>
              </div>
            </div>
            <div class="col-6">
              <div class="scope-custom-no-border">
                <p class="d-flex px-1 w-100" style="font-size: 14px; padding-top: 16px" *ngIf="!isProductReceive">
                  {{
                  "FormQuotation.RecieveMoreProduct.Description1" | translate
                  }}
                </p>
                <p class="d-flex px-1 w-100" style="font-size: 14px; padding-top: 16px" *ngIf="isProductReceive">
                  {{
                  "FormQuotation.RecieveMoreProduct.Description2" | translate
                  }}
                </p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isDebitNote">
            <div class="col-3 form-group">
              <div class="scope-custom">
                <label for="">{{
                  "FormQuotation.SendMoreProduct.Label" | translate
                  }}</label>
                <ng-container>
                  <ng-select formControlName="isProductSent" class="custom-select" [clearable]="false"
                    (change)="onSelectProductSent($event)">
                    <ng-option [value]="false" selected>
                      {{ "FormQuotation.SendMoreProduct.Option1" | translate }}
                    </ng-option>
                    <ng-option [value]="true">
                      {{ "FormQuotation.SendMoreProduct.Option2" | translate }}
                    </ng-option>
                  </ng-select>
                </ng-container>
              </div>
            </div>
            <div class="col-6">
              <div class="scope-custom-no-border">
                <p class="d-flex px-1 w-100" style="font-size: 14px; padding-top: 16px" *ngIf="!isProductSent">
                  {{ "FormQuotation.SendMoreProduct.Description1" | translate }}
                </p>
                <p class="d-flex px-1 w-100" style="font-size: 14px; padding-top: 16px" *ngIf="isProductSent">
                  {{ "FormQuotation.SendMoreProduct.Description2" | translate }}
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <hr />

      <div>
        <table class="table form-table custo-table">
          <thead>
            <tr class="text-center">
              <th colspan="2" style="width: 50px">
                #
              </th>
              <th class="tbproductcode">
                {{ "DocTable.Item" | translate }}
              </th>
              <th class="tbproductdescription">
                {{ "DocTable.Detail" | translate }}
              </th>
              <th class="tbproductstocktype">
                {{ "DocTable.StockType" | translate }}
              </th>
              <th class="tbproductamount text-center" style="width: 10%">
                {{ "DocTable.Quantity" | translate }}
              </th>
              <th class="tbproductprice text-center">
                {{ "DocTable.Price" | translate }} <br />
                ({{
                isExcludeTax
                ? ("FormQuotation.Price.Exclude" | translate)
                : ("FormQuotation.Price.Include" | translate)
                }})
              </th>
              <th class="tbproductdiscount" *ngIf="!isCreditNote">
                {{ "DocTable.Discount" | translate }}
              </th>
              <th class="tbproductnetamount">
                {{ "DocTable.PreTaxAmount" | translate }} <br />
                ({{ "FormQuotation.Price.Exclude" | translate }})
              </th>
              <th class="tbproductwht" [hidden]="!isWhtTax">
                {{ "DocTable.Wht" | translate }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <tr #formTable *ngFor="let row of rows; index as ind" cdkDrag [ngClass]="{
                'tr-description-invalid': invalidDescriptionRows.includes(ind)
              }">
              <td cdkDragHandle class="text-center" style="background: #f3f2f7; width: 50px; cursor: move">
                <span><i data-feather="menu"></i></span>
              </td>
              <td [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }" class="text-center" style="width: 50px">
                <div *ngIf="row.rowType == 'item'">
                  {{ getRowIndex(row) }}
                </div>
              </td>
              <td class="tbproductcode" [id]="'tbproductcode' + (ind + 1)"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }" style="padding: 0px 0px">
                <ng-container *ngIf="row.rowType == 'item'">
                  <ng-container *ngIf="!isLoadingItemList">
                    <ng-select style="height: 50px; width: 275px" placeholder="-- {{
                        'FormQuotation.SelectItem' | translate
                      }} --" [id]="'productcode' + (ind + 1)" [disabled]="row.rowType != 'item' || isOnlyDecrease"
                      [loading]="isLoadingItemList" (change)="onSelectedItem(ind + 1, $event)"
                      [(ngModel)]="rows[ind].itemId" [clearable]="false" placement="top" ngbTooltip="{{ rows[ind].itemCode }} - {{
                        rows[ind].itemName
                      }}" tooltipClass="custom-tooltip" [ngModelOptions]="{ standalone: true }">
                      <ng-option *ngFor="let item of itemList" [value]="item.id" [selected]="row.itemId == item.id">
                        <span *ngIf="!item.isActive">(Deprecated)</span>
                        {{ item.itemCode }} - {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </ng-container>
                </ng-container>

                <div *ngIf="isLoadingItemList" style="width: 200px; text-align: center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>

              <td style="width: 35%" class="tbproductdescription textarea-description"
                [id]="'tbproductdescription' + (ind + 1)">
                <textarea [id]="'productdescription' + (ind + 1)" [value]="row.description ? row.description : ''"
                  wrap="hard" style="resize: none" (blur)="SetDescription($event, ind + 1)"
                  (keydown)="validateLineLimit($event)"></textarea>
              </td>

              <td style="width: 5%" class="tbproductstocktype textarea-description"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }" [id]="'tbproductstocktype' + (ind + 1)">
                <ng-container *ngIf="row.rowType == 'item'">
                  <ng-select style="height: 50px; width: 100px" placeholder="-- {{
                      'FormQuotation.SelectStockType' | translate
                    }} --" [id]="'tbproductstocktype' + (ind + 1)" [(ngModel)]="rows[ind].stockType"
                    [clearable]="false" [searchable]="false" (change)="onSelectedStockType(ind + 1, $event)"
                    [ngModelOptions]="{ standalone: true }" [disabled]="
                      row.rowType != 'item' ||
                      isCreditNote ||
                      isCreateInvoice ||
                      isCreateGR
                    ">
                    <ng-option [value]="kstockType[0].value" [selected]="
                        rows[ind].stockType == kstockType[0].value ||
                        !rows[ind].stockType
                      ">
                      {{ kstockType[0].name }}
                    </ng-option>
                    <ng-option [value]="kstockType[1].value" [selected]="rows[ind].stockType == kstockType[1].value">
                      {{ kstockType[1].name }}
                    </ng-option>
                  </ng-select>
                </ng-container>
              </td>

              <!-- Quantity -->
              <td *ngIf="!isCreditDebitMode && !isCreateInvoice && !isCreateGR" class="tbproductamount"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }" [id]="'tdquantity' + (ind + 1)">
                <div *ngIf="row.rowType == 'item'" [disabled]="row.rowType != 'item'" class="d-flex align-items-center">
                  <div class="d-flex" *ngIf="isQuantityDecimal">
                    <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number"
                      (focus)="removeComma($event)" (blur)="
                        formatMoney(
                          'tbproductamount' + (ind + 1),
                          true,
                          true,
                          ind + 1,
                          'quantity',
                          isQuantityDecimal
                        )
                      " (keyup)="CalculateProductRow(ind + 1)" (keydown)="CalculateProductRow(ind + 1)"
                      (change)="CalculateProductRow(ind + 1)" type="text" value="1.00" />
                    <div class="amount-btn">
                      <a (click)="increaseAmount(ind + 1)" [ngClass]="{
                          'd-none': isOnlyDecrease
                        }">
                        <i data-feather="plus"></i>
                      </a>
                      <a (click)="decreaseAmount(ind + 1)">
                        <i data-feather="minus"></i>
                      </a>
                    </div>
                  </div>
                  <div class="d-flex" *ngIf="!isQuantityDecimal">
                    <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number"
                      (focus)="removeComma($event)" (blur)="
                        formatMoney(
                          'tbproductamount' + (ind + 1),
                          true,
                          true,
                          ind + 1,
                          'quantity',
                          isQuantityDecimal
                        )
                      " (change)="CalculateProductRow(ind + 1)" (keyup)="CalculateProductRow(ind + 1)"
                      (keydown)="CalculateProductRow(ind + 1)" type="text" [value]="
                        numQuantityArray[ind + 1]
                          ? numQuantityArray[ind + 1]
                          : 1
                      " />
                    <div class="amount-btn">
                      <a (click)="increaseAmount(ind + 1)" [ngClass]="{
                          'd-none': isOnlyDecrease
                        }">
                        <i data-feather="plus"></i>
                      </a>
                      <a (click)="decreaseAmount(ind + 1)">
                        <i data-feather="minus"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="default-item-msg" *ngIf="row.rowType == 'item'">
                  {{ "General.InStock" | translate }}:
                  <span *ngIf="row.stockType == stockType.FG">{{
                    row.inStockFGQuantity ? row.inStockFGQuantity : 0
                    }}</span>
                  <span *ngIf="row.stockType == stockType.FOC">{{
                    row.inStockFGQuantity ? row.inStockFOCQuantity : 0
                    }}</span>
                </div>
              </td>

              <!-- Quantity Create Invoice -->
              <td *ngIf="isCreateInvoice" class="tbproductamount" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error':
                    row.quantity > quantityItemDefault[ind] || row.quantity < 0
                }" [id]="'tdquantity' + (ind + 1)">
                <div *ngIf="row.rowType == 'item'" [disabled]="row.rowType != 'item'" class="d-flex align-items-center">
                  <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number"
                    (focus)="removeComma($event)" (blur)="
                      formatMoney(
                        'tbproductamount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'quantity',
                        isQuantityDecimal
                      );
                      validateProductRow(ind + 1)
                    " type="text" [value]="
                      isQuantityDecimal
                        ? (row.quantity | number : '1.2-4')
                        : (row.quantity | number)
                    " [max]="quantityItemDefault[ind]" />
                  <div class="amount-btn">
                    <a (click)="increaseAmount(ind + 1)" [ngClass]="{
                        'amount-disable': !(
                          row.quantity < quantityItemDefault[ind]
                        )
                      }">
                      <i data-feather="plus"></i>
                    </a>
                    <a (click)="decreaseAmount(ind + 1)" [ngClass]="{
                        'amount-disable': !(row.quantity !== 0)
                      }">
                      <i data-feather="minus"></i>
                    </a>
                  </div>
                </div>
                <div class="default-item-msg" *ngIf="row.rowType == 'item'">
                  {{ "General.Remain" | translate }}:
                  {{ quantityItemDefault[ind] }}
                </div>
              </td>

              <!-- Quantity Credit -->
              <td *ngIf="isCreditNote" class="tbproductamount" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error':
                    row.quantity > quantityItemDefault[ind] || row.quantity < 0
                }" [id]="'tdquantity' + (ind + 1)">
                <div *ngIf="row.rowType == 'item'" [disabled]="row.rowType != 'item'" class="d-flex align-items-center">
                  <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number"
                    (focus)="removeComma($event)" (blur)="
                      formatMoney(
                        'tbproductamount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'quantity',
                        isQuantityDecimal
                      );
                      validateProductRow(ind + 1)
                    " type="text" [value]="
                      isQuantityDecimal
                        ? (row.quantity | number : '1.2-4')
                        : (row.quantity | number)
                    " [max]="quantityItemDefault[ind]" />
                  <div class="amount-btn">
                    <a (click)="increaseAmount(ind + 1)" [ngClass]="{
                        'amount-disable': !(
                          row.quantity < quantityItemDefault[ind]
                        )
                      }">
                      <i data-feather="plus"></i>
                    </a>
                    <a (click)="decreaseAmount(ind + 1)" [ngClass]="{
                        'amount-disable': !(row.quantity !== 0)
                      }">
                      <i data-feather="minus"></i>
                    </a>
                  </div>
                </div>
                <div class="default-item-msg" *ngIf="row.rowType == 'item'">
                  {{ "General.Remain" | translate }}:
                  {{ quantityItemDefault[ind] }}
                </div>
              </td>

              <!-- Quantity Debit -->
              <td *ngIf="isDebitNote" class="tbproductamount" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error': row.quantity < 0
                }" [id]="'tdquantity' + (ind + 1)">
                <div *ngIf="row.rowType == 'item'" [disabled]="row.rowType != 'item'" class="d-flex align-items-center">
                  <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number" [ngClass]="{
                      'text-danger': row.quantity < 0
                    }" (focus)="removeComma($event)" (blur)="
                      formatMoney(
                        'tbproductamount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'quantity',
                        isQuantityDecimal
                      );
                      validateProductRow(ind + 1)
                    " type="text" [value]="
                      isQuantityDecimal
                        ? (row.quantity | number : '1.2-4')
                        : (row.quantity | number)
                    " />
                  <div class="amount-btn">
                    <!-- [ngClass]="{
                      'amount-disable': !(
                        row.quantity < quantityItemDefault[ind]
                      )
                    }" -->
                    <a (click)="increaseAmount(ind + 1)">
                      <i data-feather="plus"></i>
                    </a>
                    <a (click)="decreaseAmount(ind + 1)">
                      <i data-feather="minus"></i>
                    </a>
                  </div>
                </div>
              </td>

              <!-- Quantity GR -->
              <td *ngIf="isCreateGR" class="tbproductamount" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error': row.quantity < 0
                }" [id]="'tdquantity' + (ind + 1)">
                <div *ngIf="row.rowType == 'item'" [disabled]="row.rowType != 'item'" class="d-flex align-items-center">
                  <input [id]="'tbproductamount' + (ind + 1)" class="table-input input-number" [ngClass]="{
                      'text-danger': row.quantity < 0
                    }" (focus)="removeComma($event)" (blur)="
                      formatMoney(
                        'tbproductamount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'quantity',
                        isQuantityDecimal
                      );
                      validateProductRow(ind + 1)
                    " type="text" [value]="
                      isQuantityDecimal
                        ? (row.quantity | number : '1.2-4')
                        : (row.quantity | number)
                    " />
                  <div class="amount-btn">
                    <a (click)="increaseAmount(ind + 1)">
                      <i data-feather="plus"></i>
                    </a>
                    <a (click)="decreaseAmount(ind + 1)">
                      <i data-feather="minus"></i>
                    </a>
                  </div>
                </div>
                <div class="default-item-msg" *ngIf="row.rowType == 'item'">
                  {{ "General.Remain" | translate }}:
                  {{ quantityItemDefault[ind] }}
                </div>
              </td>

              <!-- Unit Price  -->
              <td class="tbproductprice" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error': row.unitPrice < 0
                }" *ngIf="!isCreditDebitMode && !isCreateInvoice && !isCreateGR" [id]="'tdproductprice' + (ind + 1)">
                <input [id]="'tbproductprice' + (ind + 1)" *ngIf="row.rowType == 'item'" [disabled]="
                    row.rowType != 'item' ||
                    isOnlyDecrease ||
                    row.stockType === stockType.FOC
                  " class="table-input input-number" (focus)="removeComma($event)" (blur)="
                    formatMoney(
                      'tbproductprice' + (ind + 1),
                      true,
                      true,
                      ind + 1,
                      'unitPrice'
                    );
                    CalculateProductRow(ind + 1);
                    validateProductRow(ind + 1)
                  " [ngClass]="{
                    'text-danger': row.unitPrice < 0
                  }" type="text" value="0.00" />
              </td>

              <!-- Unit Price Create IV & GR  -->
              <td class="tbproductprice" *ngIf="isCreateInvoice || isCreateGR" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error': row.unitPrice < 0
                }" [id]="'tdproductprice' + (ind + 1)">
                <input [id]="'tbproductprice' + (ind + 1)" *ngIf="row.rowType == 'item'"
                  [disabled]="row.rowType != 'item' || isOnlyDecrease" class="table-input input-number"
                  (focus)="removeComma($event)" (blur)="
                    formatMoney(
                      'tbproductprice' + (ind + 1),
                      true,
                      true,
                      ind + 1,
                      'unitPrice'
                    );
                    CalculateProductRow(ind + 1);
                    validateProductRow(ind + 1)
                  " type="text" [ngClass]="{
                    'text-danger': row.unitPrice < 0
                  }" [value]="row.unitPrice | number : '1.2-4'" />
              </td>

              <!-- Unit Price Credit Note  -->
              <td class="tbproductprice" *ngIf="isCreditNote" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error':
                    row.unitPrice > unitPriceItemDefault[ind] ||
                    row.unitPrice < 0
                }" [id]="'tdproductprice' + (ind + 1)">
                <input [id]="'tbproductprice' + (ind + 1)" *ngIf="row.rowType == 'item'"
                  [disabled]="row.rowType != 'item'" class="table-input input-number" (focus)="removeComma($event)"
                  (blur)="
                    formatMoney(
                      'tbproductprice' + (ind + 1),
                      true,
                      true,
                      ind + 1,
                      'unitPrice'
                    );
                    CalculateProductRow(ind + 1);
                    validateProductRow(ind + 1)
                  " type="text" [value]="row.unitPrice | number : '1.2-4'" [max]="unitPriceItemDefault[ind]" />
                <div class="default-item-msg" *ngIf="row.rowType == 'item' || unitPriceItemDefault[ind]">
                  {{ "General.Original" | translate }}:
                  {{ unitPriceItemDefault[ind] | number : "1.2-4" }}
                </div>
              </td>

              <!-- Unit Price Debit Note  -->
              <td class="tbproductprice" *ngIf="isDebitNote" [ngClass]="{
                  'disable-bg-color': row.rowType != 'item',
                  'is-invalid error': row.unitPrice < 0
                }" [id]="'tdproductprice' + (ind + 1)">
                <input [id]="'tbproductprice' + (ind + 1)" *ngIf="row.rowType == 'item'"
                  [disabled]="row.rowType != 'item'" class="table-input input-number" (focus)="removeComma($event)"
                  (blur)="
                    formatMoney(
                      'tbproductprice' + (ind + 1),
                      true,
                      true,
                      ind + 1,
                      'unitPrice'
                    );
                    CalculateProductRow(ind + 1);
                    validateProductRow(ind + 1)
                  " type="text" [ngClass]="{
                    'text-danger': row.unitPrice < 0
                  }" [value]="row.unitPrice | number : '1.2-4'" />
                <!-- <div class="default-item-msg" *ngIf="unitPriceItemDefault[ind]">
                  ยอดเดิม: {{ unitPriceItemDefault[ind] }}
                </div> -->
              </td>

              <!-- Discount -->
              <td class="tbproductdiscount" [ngClass]="{
                  tbproductdiscount: true,
                  'disable-bg-color': row.rowType != 'item',
                  'hide-credit': isCreditNote,
                  'is-invalid error':
                    row.discount < 0 ||
                    (row.isDiscountByPercent
                      ? row.discount > 100
                      : row.discount > row.unitPrice)
                }" [id]="'tbproductdiscountborder' + (ind + 1)" *ngIf="!isDebitNote">
                <div class="d-flex">
                  <input [id]="'tbproductdiscount' + (ind + 1)" *ngIf="row.rowType == 'item'" [disabled]="
                      row.rowType != 'item' ||
                      isOnlyDecrease ||
                      row.stockType === stockType.FOC
                    " class="table-input input-number" (focus)="
                      removeComma($event);
                      showSelectDiscountMode('discountModeSelect' + (ind + 1))
                    " (blur)="
                      delayedHideSelectDiscountMode(
                        'discountModeSelect' + (ind + 1)
                      );
                      formatMoney(
                        'tbproductdiscount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'discount'
                      );
                      CalculateProductRow(ind + 1);
                      validateProductRow(ind + 1)
                    " [value]="row.discount | number : '1.2-4'" type="text" [ngClass]="{
                      'text-danger':
                        row.discount < 0 ||
                        (row.isDiscountByPercent
                          ? row.discount > 100
                          : row.discount > row.unitPrice)
                    }" />
                  <div *ngIf="row.isDiscountByPercent" class="my-auto">%</div>
                </div>

                <div class="discountOption shadow d-none" [id]="'discountModeSelect' + (ind + 1)">
                  <p>{{ "DocFooter.SelectDiscountFormat" | translate }}</p>
                  <div class="radio">
                    <div id="radio">
                      <div class="d-block radio radio-success">
                        <div class="d-flex mb-1">
                          <input type="radio" name="discountMode{{ ind + 1 }}" [checked]="row.isDiscountByPercent"
                            (change)="
                              onChangeDiscountMode(ind + 1, true);
                              CalculateProductRow(ind + 1)
                            " />
                          <p class="my-auto ml-1">
                            {{ "General.Percent" | translate }} %
                          </p>
                        </div>
                        <div class="d-flex">
                          <input type="radio" name="discountMode{{ ind + 1 }}" [checked]="!row.isDiscountByPercent"
                            (change)="
                              onChangeDiscountMode(ind + 1, false);
                              CalculateProductRow(ind + 1)
                            " />
                          <p class="my-auto ml-1">
                            {{ "DocFooter.Baht" | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Discount Debit Note-->
              <td class="tbproductdiscount" [ngClass]="{
                  tbproductdiscount: true,
                  'disable-bg-color': row.rowType != 'item',
                  'hide-credit': isCreditNote,
                  'is-invalid error':
                    row.discount < 0 ||
                    (row.isDiscountByPercent
                      ? row.discount > 100
                      : row.discount > row.unitPrice)
                }" [id]="'tbproductdiscountborder' + (ind + 1)" *ngIf="isDebitNote">
                <div class="d-flex">
                  <input [id]="'tbproductdiscount' + (ind + 1)" *ngIf="row.rowType == 'item'"
                    [disabled]="row.rowType != 'item' || isOnlyDecrease" class="table-input input-number" (focus)="
                      removeComma($event);
                      showSelectDiscountMode('discountModeSelect' + (ind + 1))
                    " (blur)="
                      delayedHideSelectDiscountMode(
                        'discountModeSelect' + (ind + 1)
                      );
                      formatMoney(
                        'tbproductdiscount' + (ind + 1),
                        true,
                        true,
                        ind + 1,
                        'discount'
                      );
                      CalculateProductRow(ind + 1);
                      validateProductRow(ind + 1)
                    " type="text" [value]="row.discount | number : '1.2-4'" [ngClass]="{
                      'text-danger':
                        row.discount < 0 ||
                        (row.isDiscountByPercent
                          ? row.discount > 100
                          : row.discount > row.unitPrice)
                    }" />
                  <div *ngIf="row.isDiscountByPercent" class="my-auto">%</div>
                </div>
                <div class="discountOption shadow d-none" [id]="'discountModeSelect' + (ind + 1)">
                  <p>{{ "DocFooter.SelectDiscountFormat" | translate }}</p>
                  <div class="radio">
                    <div id="radio">
                      <div class="d-block radio radio-success">
                        <div class="d-flex mb-1">
                          <input type="radio" name="discountMode{{ ind + 1 }}" [checked]="row.isDiscountByPercent"
                            (change)="
                              onChangeDiscountMode(ind + 1, true);
                              CalculateProductRow(ind + 1)
                            " />
                          <p class="my-auto ml-1">
                            {{ "General.Percent" | translate }} %
                          </p>
                        </div>
                        <div class="d-flex">
                          <input type="radio" name="discountMode{{ ind + 1 }}" [checked]="!row.isDiscountByPercent"
                            (change)="
                              onChangeDiscountMode(ind + 1, false);
                              CalculateProductRow(ind + 1)
                            " />
                          <p class="my-auto ml-1">
                            {{ "DocFooter.Baht" | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Net Amount -->
              <td *ngIf="!isCreditDebitMode && !isCreateInvoice && !isCreateGR" class="tbproductnetamount"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }">
                <div class="text-dark align-items-center" *ngIf="row.rowType == 'item'"
                  [id]="'tbproductnetamount' + (ind + 1)">
                  <!-- {{ grandTotalItemDefault[ind] ? grandTotalItemDefault[ind] : '0.00' | number : "1.2-4" }} -->
                  0.00
                </div>
              </td>

              <!-- Net Amount Credit Debit IV -->
              <td *ngIf="isCreditDebitMode || isCreateInvoice || isCreateGR" class="tbproductnetamount"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }">
                <div class="text-dark align-items-center" *ngIf="row.rowType == 'item'"
                  [id]="'tbproductnetamount' + (ind + 1)">
                  {{ getPreTaxAmount[ind] | number : "1.2-2" }}
                </div>
              </td>

              <td [hidden]="!isWhtTax" id="tbproductwht" class="tbproductwht"
                [ngClass]="{ 'disable-bg-color': row.rowType != 'item' }">
                <div ngbDropdown *ngIf="row.rowType == 'item'">
                  <a ngbDropdownToggle href="javascript:void(0);" class="d-flex justify-content-end text-secondary"
                    id="tbproductwht-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div>
                      <div class="text-dark" [id]="'tbproductwht' + (ind + 1)" style="text-align: end">
                        ยังไม่ระบุ
                      </div>
                      <div class="text-muted" [id]="'tbproductwhtRate' + (ind + 1)"
                        style="text-align: end; font-size: 12px">
                        ยังไม่ระบุ
                      </div>
                    </div>
                    <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                    <a ngbDropdownItem class="d-flex align-items-center" (click)="onSelectedWHT(wht, ind + 1)"
                      *ngFor="let wht of whtList" [disabled]="row.rowType != 'item' || isOnlyDecrease">
                      {{ wht.label }}
                    </a>
                  </div>
                </div>
              </td>

              <td class="text-center" style="width: 10px" *ngIf="!isDebitNote">
                <a *ngIf="rows.length > 1" class="remove-button" (click)="removeRow(ind)">
                  <i data-feather="x"></i>
                </a>
              </td>

              <td class="text-center" style="width: 10px" *ngIf="isDebitNote">
                <a class="remove-button" (click)="removeRow(ind)">
                  <i data-feather="x"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Error Message -->
      <div class="d-block">
        <div class="d-flex justify-content-end subtotal-row" *ngIf="descriptionInvalid">
          <p class="ml-1 my-auto text-danger">
            {{ "ErrorMessage.DescNotFirstRow" | translate }}
          </p>
        </div>
        <div class="d-flex justify-content-end subtotal-row" *ngIf="quantityInvalid.includes(true)">
          <p *ngIf="!isCreditNote && !isCreateInvoice" class="ml-1 my-auto text-danger">
            {{ "ErrorMessage.QuantityMustNotBeNegative" | translate }}
          </p>
          <p *ngIf="isCreditNote || isCreateInvoice" class="ml-1 my-auto text-danger">
            {{
            "ErrorMessage.QuantityMustNotBeNegativeOrExceedTheRemaining"
            | translate
            }}
          </p>
        </div>
        <div class="d-flex justify-content-end subtotal-row" *ngIf="quantityWarning.includes(true)">
          <p *ngIf="isCreateGR" class="ml-1 my-auto" style="color: rgb(255, 110, 58)">
            {{ "ErrorMessage.QuantityGreaterThanRemaining" | translate }}
          </p>
        </div>
        <div class="d-flex justify-content-end subtotal-row" *ngIf="unitPriceInvalid.includes(true)">
          <p *ngIf="!isCreditDebitMode && !isCreateInvoice" class="ml-1 my-auto text-danger">
            {{ "ErrorMessage.ProductPriceMustNotBeNegative" | translate }}
          </p>
          <p *ngIf="isDebitNote" class="ml-1 my-auto text-danger">
            {{ "ErrorMessage.QuantityMustNotBeNegative" | translate }}
          </p>
          <p *ngIf="isCreditNote || isCreateInvoice" class="ml-1 my-auto text-danger">
            {{
            "ErrorMessage.ProductPriceMustNotBeNegativeOrExceedTheOriginalAmount"
            | translate
            }}
          </p>
        </div>
        <div class="d-flex justify-content-end subtotal-row" *ngIf="discountInvalid.includes(true)">
          <p class="ml-1 my-auto text-danger">
            {{
            "ErrorMessage.DiscountMustNotBeNegativeOrExceedTheProductPrice"
            | translate
            }}
          </p>
        </div>
      </div>

      <div [ngClass]="{
          'd-none': isOnlyDecrease
        }">
        <div class="mt-1 d-flex justify-content-between">
          <a class="btn btn-outline-primary mr-1 text-primary" (click)="addItemRow()">
            <i data-feather="plus"></i>&nbsp;{{
            "DocTable.Button.AddItem" | translate
            }}
          </a>
          <a class="btn btn-outline-dark" (click)="addDescriptionRow(true)">
            <i data-feather="plus"></i>&nbsp;{{
            "DocTable.Button.AddDes" | translate
            }}
          </a>
        </div>
      </div>
      <div *ngIf="isCreditDebitMode || isCreateInvoice || isCreateGR" class="mt-1 d-flex justify-content-end">
        <a class="btn btn-outline-primary text-primary" (click)="resetRow()">
          <i data-feather="refresh-ccw"></i>
          {{ "DocTable.Button.Reset" | translate }}
        </a>
      </div>

      <hr />

      <div class="d-flex justify-content-between" #footerForm>
        <div class="form-group">
          <label for="">{{ "DocFooter.Description" | translate }}</label>

          <textarea class="form-control" cols="60" placeholder="" rows="10" maxlength="400" style="resize: none"
            formControlName="remarks" #remarkTextarea (keydown)="validateLineLimit($event)">
          </textarea>
          <label for="">{{ "DocFooter.Validate" | translate }}
            {{ 400 - remarkTextarea.value.length }}/400</label>
        </div>

        <div class="text-end position-relative" style="margin-left: 20px; flex-shrink: 0">
          <!-- Total Discount -->
          <div *ngIf="!isCreateInvoice && !isCreateGR" class="d-flex justify-content-end"
            [ngClass]="{ 'hide-credit-div': isCreditNote }">
            <p class="my-auto">{{ "DocFooter.TotalDiscount" | translate }}</p>
            <p class="ml-1 my-auto">
              <input type="text" class="input-number form-control" [ngClass]="{
                  'text-danger': totalDiscountInvalid,
                  'is-invalid error': totalDiscountInvalid
                }" (focus)="
                  removeComma($event);
                  showSelectDiscountMode('discountAllBillModeSelect1')
                " (blur)="
                  delayedHideSelectDiscountMode('discountAllBillModeSelect1');
                  formatMoney('discountAllBill', true);
                  validateSumGrandTotal()
                " (change)="validateSumGrandTotal()" (keyup)="CalculateSummary()" (keydown)="CalculateSummary()"
                [value]="totalDiscountDefault | number : '1.2-4'" id="discountAllBill"
                formControlName="preTaxDiscount" />
            </p>
            <div *ngIf="isDiscountByPercent" class="ml-2 my-auto">%</div>
            <p *ngIf="!isDiscountByPercent" class="ml-2 my-auto">
              {{ "DocFooter.Baht" | translate }}
            </p>
          </div>

          <!-- Total Discount Create IV -->
          <div *ngIf="isCreateInvoice || isCreateGR" class="d-flex justify-content-end"
            [ngClass]="{ 'hide-credit-div': isCreditNote }">
            <p class="my-auto">{{ "DocFooter.TotalDiscount" | translate }}</p>
            <p class="ml-1 my-auto">
              <input type="text" class="input-number form-control" [ngClass]="{
                  'text-danger': totalDiscountInvalid,
                  'is-invalid error': totalDiscountInvalid
                }" (focus)="removeComma($event)" (blur)="
                  formatMoney('discountAllBill', true); validateSumGrandTotal()
                " (keyup)="CalculateSummary()" (keydown)="CalculateSummary()"
                [value]="totalDiscountDefault | number : '1.2-4'" id="discountAllBill"
                formControlName="preTaxDiscount" />
            </p>
            <p *ngIf="!isDiscountByPercent" class="ml-2 my-auto">
              {{ "DocFooter.Baht" | translate }}
            </p>
          </div>

          <div class="default-item-msg text-right text-danger" *ngIf="
              totalDiscountInvalid == true &&
              (isCreditDebitMode || isCreateInvoice || isCreateGR)
            ">
            {{ "ErrorMessage.TotalDiscountMustNotExceed" | translate }}
            {{ totalDiscountDefault | number : "1.2-4" }}
            {{ "DocFooter.Baht" | translate }}
            {{ "ErrorMessage.AndMustNotBeNegative" | translate }}
          </div>
          <div class="default-item-msg text-right text-danger" *ngIf="
              totalDiscountInvalid == true &&
              !isCreditDebitMode &&
              !isCreateInvoice &&
              !isCreateGR
            ">
            {{ "ErrorMessage.TotalDiscountMustNotBeNegative" | translate }}
          </div>

          <div class="d-flex justify-content-end subtotal-row" *ngIf="isCreditDebitMode">
            <p class="my-auto">
              {{ "DocFooter.AmountOfReferred" | translate }}
            </p>
            <p class="ml-1 my-auto" id="refOldPrice">0.00</p>
            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>

          <div class="d-flex justify-content-end subtotal-row" *ngIf="isCreditDebitMode">
            <p class="my-auto">{{ "DocFooter.NewAmount" | translate }}</p>
            <p class="ml-1 my-auto" id="refNewPrice">0.00</p>
            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>

          <div class="discountOptionSummary shadow d-none" [id]="'discountAllBillModeSelect1'"
            (mouseenter)="showSelectDiscountMode('discountAllBillMode')"
            (mouseleave)="hideSelectDiscountMode('discountAllBillMode')">
            <p>{{ "DocFooter.SelectDiscountFormat" | translate }}</p>
            <div class="radio">
              <div id="radio">
                <div class="d-block radio radio-success">
                  <div class="d-flex mb-1">
                    <input type="radio" name="discountAllBillMode" [checked]="isDiscountByPercent" (change)="
                        onChangeDiscountMode(null, true, true);
                        CalculateSummary()
                      " />
                    <p class="my-auto ml-1">
                      {{ "General.Percent" | translate }} %
                    </p>
                  </div>
                  <div class="d-flex">
                    <input type="radio" name="discountAllBillMode" [checked]="!isDiscountByPercent" (change)="
                        onChangeDiscountMode(null, false, true);
                        CalculateSummary()
                      " />
                    <p class="my-auto ml-1">
                      {{ "DocFooter.Baht" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto" *ngIf="!isDebitNote">
              {{
              isVatExempted
              ? ("DocFooter.NetAmount" | translate)
              : ("DocTable.PreTaxAmount" | translate)
              }}
            </p>
            <p class="my-auto" *ngIf="isDebitNote">
              {{
              isVatExempted
              ? ("DocFooter.NetAmount" | translate)
              : ("DocTable.PreNewAmount" | translate)
              }}
            </p>
            <p class="ml-1 my-auto" id="summaryTaxExclusive">0.00</p>
            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>

          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "DocFooter.Vat" | translate }}</p>

            <div class="d-flex ml-1">
              <select class="custom-select text-dark" (change)="onSelectedTax($event)" formControlName="taxRate"
                [id]="'producttax'" readonly="true" disabled>
                <option [value]="-1">{{ "DocFooter.Not" | translate }}</option>
                <option [value]="0.0">0%</option>
                <option [value]="7.0">7%</option>
              </select>
              <p class="ml-1 my-auto" id="summaryTaxPrice">0.00</p>
            </div>

            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>

          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "DocFooter.DeliveryPrice" | translate }}</p>

            <input *ngIf="!isCreditDebitMode" type="text" class="input-number form-control ml-1" style="color: #6e6b7b"
              id="shippingFee" (change)="CalculateSummary()" [value]="shippingFeeDecimal" disabled />

            <input *ngIf="isCreditDebitMode" type="text" class="input-number form-control ml-1" style="width: 100px"
              id="shippingFee" formControlName="shippingFee" (focus)="removeComma($event)"
              (blur)="formatMoney('shippingFee', true)" (change)="CalculateSummary()"
              [value]="shippingFee | number : '1.2-2'" />

            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>

          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "DocFooter.NetAmount" | translate }}</p>
            <p class="ml-1 my-auto" id="summaryPrice">0.00</p>
            <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
          </div>
          <div class="justify-content-end subtotal-row" [hidden]="!isWhtTax">
            <div class="d-flex justify-content-end subtotal-row">
              <p class="my-auto">ภาษีหัก ณ ที่จ่าย</p>
              <p class="ml-1 my-auto">
                <input type="text" class="input-number form-control" (focus)="removeComma($event)"
                  (blur)="formatMoney('wht')" (keyup)="EditWhtPrice($event.target.value)"
                  (keydown)="EditWhtPrice($event.target.value)" id="summaryWhtPrice" formControlName="salesWHT" />
              </p>
              <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
            </div>
          </div>

          <div class="w-100 d-flex subtotal-row flex-column">
            <div class="amount-line"></div>
            <div class="d-flex justify-content-end subtotal-row">
              <p class="my-auto" *ngIf="!isCreditNote">
                {{ "DocFooter.GrandTotal" | translate }}
              </p>
              <p class="my-auto" *ngIf="isCreditNote">
                {{ "DocFooter.GrandTotalRepaid" | translate }}
              </p>
              <p class="ml-1 my-auto" id="grandTotalPrice">
                {{ 0 + shippingFee | number : "1.2-2" }}
              </p>
              <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
            </div>
            <div>
              <div class="amount-line"></div>
              <div class="amount-line"></div>
            </div>
            <div class="d-flex justify-content-end subtotal-row" *ngIf="isInvalidSumTotal()">
              <p class="ml-1 my-auto text-danger">
                {{ "ErrorMessage.GrandTotalAmountMustNotBe0Baht" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-btn" *ngIf="showSubmitBtn">
        <div class="d-flex align-items-center">
          <!-- <strong class="mr-2">
            {{ docCode }}
          </strong> -->

          <a class="btn btn-outline-dark" (click)="openCancelModal(quotationObj)">
            {{ "Form.Cancel" | translate }}
          </a>
        </div>

        <div></div>
        <div class="d-flex">
          <div>
            <div ngbDropdown [placement]="'bottom-left'" class="btn-group">
              <button type="button" class="btn btn-outline-success" (click)="openConfirmModal(quotationObj, true)"
                rippleEffect [disabled]="
                  totalDiscountInvalid ||
                  isAnyItemInvalid() ||
                  descriptionInvalid
                ">
                <i data-feather="save" class="mr-50"></i>
                <span *ngIf="data.transactionStatusId != documentStatus.Accepted">
                  {{
                  isEditing
                  ? ("General.Save" | translate)
                  : ("Form.SaveDraft" | translate)
                  }}
                </span>
                <span *ngIf="data.transactionStatusId == documentStatus.Accepted">
                  {{
                  isEditing
                  ? ("General.Save" | translate)
                  : ("General.Save" | translate)
                  }}
                </span>
              </button>
            </div>

            <div ngbDropdown [placement]="'bottom-left'" class="btn-group ml-2">
              <button type="button" class="btn btn-primary" rippleEffect
                *ngIf="data.transactionStatusId != documentStatus.Accepted"
                (click)="openConfirmModal(quotationObj, false)" [disabled]="
                  totalDiscountInvalid ||
                  isAnyItemInvalid() ||
                  descriptionInvalid ||
                  isInvalidSumTotal()
                ">
                <i data-feather="check" class="mr-50"></i>

                {{ "Form.ApproveDoc" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #tipContent>{{ itemList[0].itemCode }} - {{ itemList[0].name }}</ng-template>