import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Calendar,
  CalendarApi,
  CalendarOptions,
  CalendarRoot,
  EventClickArg,
  EventSourceInput,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { BookingCalendarModalComponent } from './booking-calendar-modal/booking-calendar-modal.component';
import { ApiService } from 'app/main/service/api.service';
import Branch, { branchList } from 'app/main/model/Branch';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ComponentsService } from 'app/main/components/components.service';
import { event } from 'jquery';
import { error } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApprovalModalComponent } from '../booking-approval/approval-modal/approval-modal.component';

@Component({
  selector: 'app-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingCalendarComponent implements OnInit, OnDestroy {
  contentHeader: object;
  branchList: Branch[] = [];
  selectedBranch: Branch[] = [];
  apiPath: string = 'MeetingRoomBooking';
  demonList: any[] = [];
  originalDemonList: any[] = [];

  finalData: any;

  @BlockUI() blockUI: NgBlockUI;

  searchData: any = {
    isDelete: false,
    isActive: true,
  };

  // Public
  public slideoutShow = false;
  public events = [];
  public event: EventSourceInput;

  @ViewChild('bookingCalendar') bookingCalendar: FullCalendarComponent;

  public calendarOptions: CalendarOptions;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CalendarService} _calendarService
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: DashboardService,
    private _coreConfigService: CoreConfigService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _componentService: ComponentsService,
    private activeModal: NgbModal,
    private _translateService: TranslateService
  ) {
    var self = this;
    this._unsubscribeAll = new Subject();
    
    this.getBranchList().then(() => {
      this.getMeetingBookingList();
    });

    

    this.calendarOptions = {
      headerToolbar: {
        start: 'sidebarToggle, prev,  next, title,',
        center: 'today',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth, ',
      },
      initialView: 'dayGridMonth',
      initialEvents: this.events,
      weekends: true,
      editable: false,
      eventDisplay: 'block',
      eventTimeFormat: {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },

      eventResizableFromStart: true,
      selectable: true,
      customButtons: {
        myCustomButton: {
          text: 'dayGridDropdown',
          click: () => {
            self.changeView('dayGridMonth');
          },
        },
      },
      height: '80vh',
      navLinks: true,
      eventClick: this.handleUpdateEventClick.bind(this),
      eventClassNames: this.eventClass.bind(this),
      select: this.handleDateSelect.bind(this),
      selectAllow: (selectInfo) => {
        let tempDate = new Date();
        tempDate.setDate(selectInfo.start.getDate() + 1);
        return selectInfo.end.getDate() === tempDate.getDate(); // prevent multiple date select
      },
    };
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Add Event Class
   *
   * @param s
   */
  eventClass(s) {
    const defaultColor = 'primary';

    // Map branch colors to their respective names
    const branchColors = {};
    this.branchList.forEach((branch) => {
      branchColors[branch.name] = branch.branchHexColor;
    });

    // Get the color for the current event's branch
    const colorName =
      branchColors[s.event._def.extendedProps.branch] || defaultColor;

    // Return the appropriate background color class
    // return `bg-light-${colorName}`;
  }

  /**
   * Update Event
   *
   * @param eventRef
   */
  handleUpdateEventClick(eventRef: any) {
    const tmpEventRef = new EventRef();
    tmpEventRef.allDay = eventRef.event.allDay;
    tmpEventRef.extendedProps = eventRef.event.extendedProps as any;

    console.log(tmpEventRef);
    this.openBookingCalendarModal(eventRef, true, false);
    this._calendarService.updateCurrentEvent(eventRef);
  }

  changeView(view: string) {
    this.bookingCalendar.getApi().changeView(view);
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * Date select Event
   *
   * @param eventRef
   */
  handleDateSelect(eventRef) {
    // const newEvent = new EventRef();
    // newEvent.start = eventRef.start;
    const DateNow = new Date(Date.now());
    // console.log(eventRef);
    eventRef.start = new Date(
      DateNow.getFullYear(),
      DateNow.getMonth(),
      DateNow.getDate(),
      0,
      0
    );
    // console.log(newEvent);

    this.openBookingCalendarModal(eventRef, false, true);
    console.log(eventRef);

    // this._coreSidebarService
    //   .getSidebarRegistry('calendar-event-sidebar')
    //   .toggleOpen();
    this._calendarService.onCurrentEventChange.next(eventRef);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.blockUI.start();

    this._calendarService.onCurrentEventChange.subscribe((res) => {
      this.event = res;
    });

    this.contentHeader = {
      headerTitle: 'Dashboard.BookingCalendar',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Calendar's custom button on click toggle sidebar
   */
  ngAfterViewInit() {
    var self = this;

    this.calendarOptions.customButtons = {
      sidebarToggle: {
        text: '',
        click() {
          self.toggleSidebar('calendar-main-sidebar');
        },
      },
    };
  }

  createEvent() {
    this.isDataEmpty = true;
    const DateNow = new Date(Date.now());
    console.log(DateNow);
    const newEven = new EventRef();
    newEven.start = new Date(
      DateNow.getFullYear(),
      DateNow.getMonth(),
      DateNow.getDate(),
      0,
      0
    );
    console.log(newEven.start);
    console.log(newEven);
    this.openBookingCalendarModal(newEven, false, true);
    this._calendarService.onCurrentEventChange.next(newEven);
  }
  isDataEmpty: boolean = false;
  openBookingCalendarModal(eventRef, isEditData, isCreate) {
    const modalRef = this._modalService.open(BookingCalendarModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'Booking Calendar';
    modalRef.componentInstance.eventRef = eventRef;
    modalRef.componentInstance.isEditData = isEditData;
    modalRef.componentInstance.isCreate = isCreate;
    modalRef.componentInstance.isDataEmpty = this.isDataEmpty;

    modalRef.componentInstance.callBackFunc.subscribe((preparedData) => {
      this.finalData = preparedData;
      console.log(this.finalData);
      this.confirmModal(this.finalData, isEditData, isCreate);
      console.log(isEditData);
    });

    modalRef.componentInstance.openDeleteModalFunc.subscribe(() => {
      this.openDeleteModal(eventRef.event);
    });
  }

  confirmModal(data, isEditData, isCreate) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    if (isEditData) {
      modalRef.componentInstance.title = this._translateService.instant(
        'BookingCalendar.Modal.UpdateEvent'
      );
      modalRef.componentInstance.detail = this._translateService.instant(
        'BookingCalendar.Modal.ConfirmUpdate'
      );
    } else {
      modalRef.componentInstance.title = this._translateService.instant(
        'BookingCalendar.Modal.CreateEvent'
      );
      modalRef.componentInstance.detail = this._translateService.instant(
        'BookingCalendar.Modal.ConfirmCreate'
      );
    }
    modalRef.componentInstance.isSubmit = true;

    modalRef.componentInstance.callBackFunc.subscribe(() => {
      if (isCreate) {
        this.addBooking(data);
      } else {
        this.updateData(data, data.id);
        console.log('update');
      }
    });
  }
  openDeleteModal(eventRef: EventRef) {
    const modalRef = this._modalService.open(ApprovalModalComponent, {
      centered: true,
      size: '',
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'ยืนยันการปฏิเสธกิจกรรม';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะปฏิเสธกิจกรรม ${eventRef.title} หรือไม่`;

    modalRef.componentInstance.callBackFunc.subscribe(
      () => {
        this.deleteEvent(
          eventRef.extendedProps.demonId,
          eventRef.extendedProps.remarks
        );

        this.getMeetingBookingList();
      },
      (error) => {
        console.log(error);
        this._componentService.ErrorSwal();
      }
    );
  }

  deleteEvent(id, remarks) {
    const stateEnum = 2;
    this._apiService
      .SetBookingEnumState('MeetingRoomBooking', id, { stateEnum, remarks })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this._componentService.SuccessSwal();
        this._modalService.dismissAll();
        this.getMeetingBookingList();
      });
  }

  // patchStateEnum(bookId, remarks) {
  //   console.log(bookId);
  //   const stateEnum = 1;
  //   const id = bookId;

  //   this._apiService
  //     .SetBookingEnumState(this.apiPath, id, { stateEnum, remarks })
  //     .subscribe(
  //       (res) => {
  //         this._componentService.SuccessSwal();
  //         this._modalService.dismissAll();
  //         this.getMeetingBookingList();
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  // }

  updateData(data, id) {
    this._apiService
      .UpdateDataById(this.apiPath, id, data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this._componentService.SuccessSwal();
          this.getMeetingBookingList();
          this._modalService.dismissAll();
          console.log(res);
        },
        (error) => {
          console.log(error);
          console.error(error.error.data.message);
          this._componentService.ErrorSwal('Error', error.error.data.message);
        }
      );
  }
  addBooking(data) {
    console.log(data);

    this._apiService
      .AddData(this.apiPath, data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          const patchData = res.data.resultData[0];
          console.log(patchData);
          this._componentService.SuccessSwal();
          // this.patchStateEnum(patchData, data.remarks);
          this.getMeetingBookingList();
          this._modalService.dismissAll();
        },
        (error) => {
          console.error('Error adding data:', error);
          this._componentService.ErrorSwal(
            error.error.messageCodes,
            error.error.message
          );
        }
      );
  }

  demonListFunc(res) {
    this.demonList = res;
    this.event = this.demonList.map((demon) => {
      const startDate = new Date(demon.startDate).getTime();
      const endDate = new Date(demon.endDate).getTime();
      const date = new Date(demon.startDate).getTime();
      const branch = this.branchList.find(
        (branch) => branch.id == demon.branchId
      );

      return {
        id: demon.id,
        title: demon.topic,
        start: startDate,
        end: endDate,
        allday: false,
        periodTimeEnum: demon.periodTimeEnum,
        description: demon.remarks,
        color: demon.bookingHexColor,
        demonId: demon.id,

        extendedProps: {
          periodTimeEnum: demon.periodTimeEnum,
          remarks: demon.remarks,
          branchId: demon.branchId,
          customerId: demon.customerId,
          startDate: demon.startDate,
          endDate: demon.endDate,
          date: date,
          bookingHexColor: demon.bookingHexColor,
          stateEnum: demon.stateEnum,
        },
      };
    });
    this.blockUI.stop();

    this.calendarOptions.events = this.event.filter((e) =>
      this.selectedBranch.includes(e.extendedProps.branchId)
    );

  }

  getMeetingBookingList() {
    this._apiService
      .GetAllData(`${this.apiPath}?StateEnum=1`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.demonList = res.data.resultData;
        this.originalDemonList = res.data.resultData;
        this.demonListFunc(this.demonList);
      });
  }

  getBranchList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._apiService
        .GetAllData('Branch?SortPath=createDate', this.searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.branchList = res.data.resultData;
            this.branchList.forEach((b) => {
              this.selectedBranch.push(b.id);
              b.isChecked = true;
            });
            console.log(this.branchList);
            resolve();
          },
          (err) => {
            reject();
          }
        );
    });
  }
  checkedList: any[] = [];
  isChecked: boolean;
  isAllBranchChecked: boolean = true;

  onSelectedBranch(event: any, branch: Branch) {
    this.demonList = this.originalDemonList;
    this.isChecked = true;
    if (event.target.checked) {
      this.selectedBranch.push(branch.id);
      branch.isChecked = true;

      if (this.selectedBranch.length == this.branchList.length) {
        this.isAllBranchChecked = true;
      }
    } else {
      branch.isChecked = false;
      const index = this.selectedBranch.indexOf(branch.id);
      if (index !== -1) {
        this.selectedBranch.splice(index, 1);
      }
      this.isAllBranchChecked = false;
    }

    this.checkedList = this.demonList.filter((b) =>
      this.selectedBranch.includes(b.branchId)
    );

    this.demonListFunc(this.checkedList);
  }

  checkHaveSelectedBranch(branch: Branch) {
    return this.selectedBranch.includes(branch.id);
  }

  onSelectedAllBranch(event: any) {
    if (event.target.checked) {
      this.isAllBranchChecked = true;
      this.branchList.map((b) => {
        b.isChecked = true;
      });
      this.selectedBranch = this.branchList.map((b) => b.id);
      this.checkedList = this.originalDemonList;
    } else {
      this.branchList.map((b) => {
        b.isChecked = false;
      });
      this.selectedBranch = [];
      this.checkedList = [];
    }

    this.demonListFunc(this.checkedList);
  }
}

export class EventRef {
  id = ' ';
  url: string;
  title: string = '';
  start: Date;
  end: Date;
  allDay = false;
  calendar: '';
  color: string;

  extendedProps: any = {
    location: '',
    description: '',
    addGuest: [],
    branchId: '',
    remarks: '',
    periodTimeEnum: null,
    customerId: '',
    startTime: null,
    endTime: null,
    stateEnum: null,
    demonId: '',
    date: '',
    bookingHexColor: '',
  };
}
