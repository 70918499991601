export enum EKYCStatusEnum {
  WaitApprove = 0,
  Approved = 1,
  Reject = 2,
}

export enum EKYCVerifyStatusEnum {
  WaitForVerify = 0,
  Verified = 1,
  Rejected = 2,
}

export enum EKYCVerifyTypeEnum {
  IdCardImage = 0,
  PersonalWithIdCardImage = 1,
  BookbankImage = 2,
  BookbankDetail = 3,
}

export class EKYCDetail {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  masterBankNameId: number = null;
  bankAccountName: string;
  bankAccountNo: string;
  verifyIdCardBy: string;
  verifyPersonalWithIdCardBy: string;
  verifyBookbankBy: string;
  verifyBookbankDetailBy: string;
  verifyIdCardImageStatusEnum: number = null;
  verifyPersonalWithIdCardImageStatusEnum: number = null;
  verifyBookbankImageStatusEnum: number = null;
  verifyBookbankDetailStatusEnum: number = null;
}

export interface EKYCBankHistory {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  ekycId: string;
  bankName: string;
  customerFirstName: string;
  customerLastName: string;
  bankAccountName: string;
  bankAccountNo: string;
  verifyBy: string;
  verifyStatusEnum: number;
  verifyTypeEnum: number;
  verifyStatusName: string;
  verifyTypeName: string;
}

export interface EKYCImageHistory {
  coverMediaFile: any;
  mediaFiles: any;
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  ekycId: string;
  fileId: string;
  verifyBy: string;
  verifyStatusEnum: number;
  verifyTypeEnum: number;
  verifyStatusName: string;
  verifyTypeName: string;
}
