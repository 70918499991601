import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { Subject } from 'rxjs';
import { AddCustomerModalComponent } from '../add-customer-modal/add-customer-modal.component';
import { EventEvidenceModalComponent } from '../event-evidence-modal/event-evidence-modal.component';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';

@Component({
  selector: 'app-event-attendance',
  templateUrl: './event-attendance.component.html',
  styleUrls: ['./event-attendance.component.scss'],
})
export class EventAttendanceComponent implements OnInit {
  contentHeader: ContentHeader;
  isLoading: boolean = false;
  customerList: Customer[] = [];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  imageEvidenceList :any[] = []

  pageSize: number = 10;
  searchData: any = {};

  currentLang: string = '';

  event = {
    id: 'd3df8615-94bb-4ddb-902e-0a75be0d94b2',
    name: 'Test Event',
    startDate: '2024-03-05T17:00:00.000Z',
    endDate: '2024-03-10T07:00:00.000Z',
    detail:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel natus, inventore totam aut tempore id voluptatum ex veritatis neque repellendus obcaecati sint esse corrupti, accusantium numquam rem perferendis, consequuntur odit.',
    createDate: '2024-03-01T02:49:28.636Z',
    createBy: 'sadmin',
    updateDate: '2024-03-02T02:49:28.636Z',
    updateBy: 'sadmin',
    isActive: true,
    isDelete: false,
  };

  constructor(
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _fileApiService : FilesApiService,
    private _componentsService : ComponentsService,
    private _modalService: NgbModal
  ) {
    // this.loadCustomerList()
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this.contentHeader = {
      headerTitle: 'Event Manage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      // order: [[2, 'asc']],
      // ordering: false,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'detail', targets: 1 },
        { name: 'rankName', targets: 2 },
        { orderable: false, targets: 3 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.customerList = [];
        self.SetLoadingState();

        let defaultSortName = 'detail';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.getCustomerList().then((res) => {
          self.customerList = res.data.resultData;
          this.imageEvidenceList = [];

          

          self.SetLoadedState();
          callback({
            recordsTotal: res.data.itemCount,
            recordsFiltered: res.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  getCustomerList(): Promise<CommonResponse<Customer>> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData('customer', this.searchData).subscribe(
        (res: CommonResponse<Customer>) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  openAddCustomerModal() {
    const modalRef = this._modalService.open(AddCustomerModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
  }

  openCustomerEvidence(customerId: string) {
    const modalRef = this._modalService.open(EventEvidenceModalComponent,{
      centered: true,
      backdrop: 'static',
      size: 'lg'
    })
    this.getDummyData()

    modalRef.componentInstance.imageEvidenceList = this.imageEvidenceList;
    modalRef.componentInstance.customerData =  this.customerList.find((c)=> c.id == customerId)
    console.log(modalRef.componentInstance.customerData)
    console.log(customerId)
    console.log(modalRef.componentInstance.imageEvidenceList)


  }

  getEvidence(mediaFiles: { id: string; ordinal: number }[]){
    mediaFiles.map((f)=> {
      let imgPath:string = this._fileApiService.getImage(f.id);
      this.imageEvidenceList.push({
        file: imgPath,
        ordinal: f.ordinal,

      });
    });

    this.imageEvidenceList.sort((a,b) => a.ordinal - b.ordinal)
  }

  getDummyData(){
    this.imageEvidenceList = [
      { file: 'https://via.placeholder.com/500x300', ordinal: 1 },
      { file: 'https://via.placeholder.com/500x300', ordinal: 2 },
      { file: 'https://via.placeholder.com/2000x1080', ordinal: 3 }
    ];
  }

  loadCustomerList() {
    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }
}
