<section class="sale-dashboard" *ngIf="chartData">
  <div class="row justify-content-between mb-3">
    <div class="col-12 col-lg-6">
      <h5 class="section-title" style="align-self: center">SV / Bonus</h5>
    </div>
    <div class="col-12 col-lg-6">
      <div class="d-flex align-items-center justify-content-end mt-md-0 mt-1 range-datepicker">

        <div class="period-pick" ngbDropdown>
          <button ngbDropdownToggle aria-expanded="false">
            <span class="img">
              <img src="../../../../assets/images/sv-bonus/icon/icon-calendar.svg" alt="" />
            </span>
            <span class="text">{{ periodTeam }}</span>
          </button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li class="dropdown-item" ngbDropdownItem (click)="selectedPeriod(item.id, item.startDate, item.endDate)"
              *ngFor="let item of periodList">
              {{ item.startDate | date: "dd/MM/yyyy" }} - {{ item.endDate | date: "dd/MM/yyyy" }}
            </li>
          </ul>
        </div>

        <a (click)="search()" class="btn btn-primary ml-1">{{
          "General.Search" | translate
          }}</a>
      </div>
    </div>
  </div>

  <div class="bonus-card-bar-pc row mt-2 mb-2">
    <div class="col-12 col-md-3">
      <ng-container *ngTemplateOutlet="personalBonusCard; context {needForContact: personalBonusAlert}"></ng-container>
    </div>
    <div class="col-12 col-md-3">
      <ng-container *ngTemplateOutlet="downlineBonusCard; context {needForContact: downlineBonusAlert}"></ng-container>
    </div>
    <div class="col-12 col-md-3">
      <ng-container *ngTemplateOutlet="systemBonusCard; context {needForContact: systemBonusAlert}"></ng-container>
    </div>
    <div class="col-12 col-md-3">
      <ng-container *ngTemplateOutlet="totalRevenueCard"></ng-container>
    </div>
  </div>

  <div class="bonus-card-bar-m mt-2 mb-2">
    <div class="col-12 px-0 col-md-3">
      <ng-container *ngTemplateOutlet="totalRevenueCard"></ng-container>
    </div>
    <div [ngbCollapse]="isCollapsed">
      <div class="col-12 px-0 col-md-3">
        <ng-container
          *ngTemplateOutlet="personalBonusCard; context {needForContact: personalBonusAlert}"></ng-container>
      </div>
      <div class="col-12 px-0 col-md-3">
        <ng-container
          *ngTemplateOutlet="downlineBonusCard; context {needForContact: downlineBonusAlert}"></ng-container>
      </div>
      <div class="col-12 px-0 col-md-3">
        <ng-container *ngTemplateOutlet="systemBonusCard; context {needForContact: systemBonusAlert}"></ng-container>
      </div>
    </div>
    <div class="divider">
      <div class="divider-text">
        <button type="button" class="btn btn-outline-secondary" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="isCollapsed" aria-controls="collapseExample4" rippleEffect>
          {{ isCollapsed ? "ดูโบนัสทั้งหมด" : "ซ่อนโบนัส" }}
          <i class="font-medium-2" data-feather="chevron-down"></i>
        </button>
      </div>
    </div>
  </div>

  <p class="text-end text-danger" *ngIf="chartData?.actualTotal != 0">* โปรดดูเงื่อนไขการจ่าย Bonus ใน Bonus plan</p>

  <div class="row mb-2">
    <div class="col-12 col-lg-7">
      <div class="">
        <div id="sv-bonus-chart" class="sv-bonus-chart" #SVBonusChartRef>
          <apx-chart [series]="SVBonusChart.series" [chart]="SVBonusChart.chart" [xaxis]="SVBonusChart.xaxis"
            [yaxis]="SVBonusChart.yaxis" [stroke]="SVBonusChart.stroke" [tooltip]="SVBonusChart.tooltip"
            [colors]="SVBonusChart.colors" [legend]="SVBonusChart.legend" [dataLabels]="SVBonusChart.dataLabels"
            [plotOptions]="SVBonusChart.plotOptions" [grid]="SVBonusChart.grid"></apx-chart>
        </div>
      </div>

      <div class="series-bar mb-2">
        <ng-container *ngTemplateOutlet="
            seriesColumn;
            context: {
              title: 'Personal Bonus',
              unitTitle1: 'SV',
              unitColor1: chartColors.column.personal.sv,
              unitTitle2: 'โบนัส',
              unitColor2: chartColors.column.personal.bonus,
              needForContact: personalBonusAlert
            }
          "></ng-container>

        <ng-container *ngTemplateOutlet="
            seriesColumn;
            context: {
              title: 'Downline Bonus',
              unitTitle1: 'SV',
              unitColor1: chartColors.column.downline.sv,
              unitTitle2: 'โบนัส',
              unitColor2: chartColors.column.downline.bonus,
              needForContact: downlineBonusAlert
            }
          "></ng-container>

        <ng-container *ngTemplateOutlet="
            seriesColumn;
            context: {
              title: 'System Bonus',
              unitTitle1: 'SV',
              unitColor1: chartColors.column.system.sv,
              unitTitle2: 'โบนัส',
              unitColor2: chartColors.column.system.bonus,
              needForContact: systemBonusAlert
            }
          "></ng-container>
      </div>
    </div>
    <div class="col-12 col-lg-5">
      <div class="card-body" style="border-radius: 15px; background: rgba(230, 241, 255, 0.5)">
        <h5 class="section-title">ภาพรวมรายได้ภายในปี 2023</h5>
        <div id="revenue-chart" #RevenueChartRef>
          <apx-chart [series]="RevenueChart.series" [chart]="RevenueChart.chart" [xaxis]="RevenueChart.xaxis"
            [yaxis]="RevenueChart.yaxis" [stroke]="RevenueChart.stroke" [tooltip]="RevenueChart.tooltip"
            [colors]="RevenueChart.colors" [legend]="RevenueChart.legend" [dataLabels]="RevenueChart.dataLabels"
            [plotOptions]="RevenueChart.plotOptions" [grid]="RevenueChart.grid"></apx-chart>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #seriesColumn let-title="title" let-unitTitle1="unitTitle1" let-unitColor1="unitColor1"
  let-unitTitle2="unitTitle2" let-unitColor2="unitColor2" let-needForContact="needForContact">
  <div class="d-block">
    <div class="series-column">
      <div>{{ title }}:&nbsp;</div>
      <div class="d-flex">
        <div class="d-flex mr-1">
          <div class="color-dot" [ngStyle]="{ 'background-color': unitColor1 }"></div>
          <p style="align-self: center">{{ unitTitle1 }}</p>
        </div>
        <div class="d-flex">
          <div class="color-dot" [ngStyle]="{ 'background-color': unitColor2 }"></div>
          <p style="align-self: center">{{ unitTitle2 }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="needForContact" class="text-danger text-center mt-1">*** กรุณาติดต่อเจ้าหน้าที่ ***</div>
  </div>
</ng-template>

<ng-template #personalBonusCard let-needForContact="needForContact">
  <div class="card-body w-100 border rounded personal-bonus-card">
    <div class="d-flex custom-card-header">
      <div class="card-icon">
        <img src="assets/images/sv-bonus/icon/icon-personal-sale.svg" />
      </div>
      <div class="card-title d-flex">
        {{ 'MLM.PersonalBonus' | translate }} ({{
        chartData.personalSummary.percentage
        ? chartData.personalSummary.percentage
        : 0
        }}%) <div *ngIf="needForContact" class="text-danger text-center" ngbTooltip="กรุณาติดต่อเจ้าหน้าที่">&nbsp;***
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between custom-card-detail">
      <div class="w-100 card-secton-line">
        <p class="price-text text-center">
          {{ (chartData.personalSummary.sv ? chartData.personalSummary.sv : 0) | number }}
        </p>
        <p class="unit-text text-center">SV</p>
      </div>
      <div class="w-100">
        <p class="price-text text-center">
          {{ (chartData.personalSummary.bonus ? chartData.personalSummary.bonus - chartData.personalSummary.voidValue :
          0 )| number }}
        </p>
        <p class="unit-text text-center">{{ 'MLM.Bonus' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #downlineBonusCard let-needForContact="needForContact">
  <div class="card-body w-100 border rounded downline-bonus-card">
    <div class="d-flex custom-card-header">
      <div class="card-icon">
        <img src="assets/images/sv-bonus/icon/icon-downline-bonus.svg" />
      </div>
      <div class="card-title d-flex">
        {{ 'MLM.DownlineBonus' | translate }} ({{
        chartData.downline.percentage
        ? chartData.downline.percentage
        : 0
        }}%) <div *ngIf="needForContact" class="text-danger text-center" ngbTooltip="กรุณาติดต่อเจ้าหน้าที่">&nbsp;***
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between custom-card-detail">
      <div class="w-100 card-secton-line">
        <p class="price-text text-center">
          {{
          (chartData.downline.sv
          ? chartData.downline.sv
          : 0) | number
          }}
        </p>
        <p class="unit-text text-center">SV</p>
      </div>
      <div class="w-100">
        <p class="price-text text-center">
          {{
          (chartData.downline.bonus
          ? chartData.downline.bonus - chartData.downline.voidValue
          : 0) | number
          }}
        </p>
        <p class="unit-text text-center">{{ 'MLM.Bonus' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #systemBonusCard let-needForContact="needForContact">
  <div class="card-body w-100 border rounded system-bonus-card">
    <div class="d-flex custom-card-header">
      <div class="card-icon">
        <img src="assets/images/sv-bonus/icon/icon-system-bonus.svg" />
      </div>
      <div class="card-title d-flex">
        {{ 'MLM.SystemBonus' | translate }} ({{
        chartData.system.percentage
        ? chartData.system.percentage
        : 0
        }}%) <div *ngIf="needForContact" class="text-danger text-center" ngbTooltip="กรุณาติดต่อเจ้าหน้าที่">&nbsp;***
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between custom-card-detail">
      <div class="w-100 card-secton-line">
        <p class="price-text text-center">
          {{
          (chartData.system.sv
          ? chartData.system.sv
          : 0) | number
          }}
        </p>
        <p class="unit-text text-center">SV</p>
      </div>
      <div class="w-100">
        <p class="price-text text-center">
          {{
          (chartData.system.bonus
          ? chartData.system.bonus
          : 0 )| number
          }}
        </p>
        <p class="unit-text text-center">{{ 'MLM.Bonus' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #totalRevenueCard>
  <div class="card-body w-100 border rounded total-revenue-card">
    <div class="d-flex custom-card-header">
      <div class="card-icon">
        <img src="assets/images/sv-bonus/icon/icon-coin.svg" />
      </div>
      <div class="card-title">{{'MLM.TotalIncome' | translate }}</div>
    </div>

    <div class="d-flex justify-content-center custom-card-detail">
      <div class="">
        <p class="price-text text-center" *ngIf="chartData?.actualTotal == 0">{{ chartData.total - chartData.voidValue | number }}
        </p>
        <p class="price-text text-center" *ngIf="chartData?.actualTotal != 0">{{ chartData.total - chartData.voidValue | number }}
          <span class="text-danger">({{chartData.actualTotal | number }})*</span>
        </p>
        <p class="unit-text text-center">{{ 'General.Baht' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>