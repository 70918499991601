import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private tokenUpdated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            if (!this.refreshTokenInProgress) {
              this.refreshTokenInProgress = true;
              return this._authenticationService.refreshToken().pipe(
                switchMap(() => {
                  let token = this._authenticationService.tokenValue;
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${token?.accessToken}`,
                    },
                  });
                  this.refreshTokenInProgress = false
                  this.tokenUpdated$.next(true);
                  return next.handle(request);
                })
              );
            } else {
              return this.tokenUpdated$.pipe(
                filter(updated => updated === true),
                take(1),
                switchMap(() => {
                  let token = this._authenticationService.tokenValue;
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${token?.accessToken}`,
                    },
                  });
                  return next.handle(request)
                })
              );
            }
          case 403:
            // this._router.navigate(['/miscellaneous/not-authorized']);
            this._authenticationService.logout()
            return throwError(err);

          default:
            // MessageBox.show({
            //     title: `Code : ${err.error.data?.errorCode}`,
            //     contentColor: 'danger',
            //     text: err.error.data?.message,
            //     icon: 'error',
            //     primaryButton: {
            //         color: 'danger',
            //         text: 'ปิด'
            //     }
            // })
            return throwError(err);
        }
      })
    );
  }
}
