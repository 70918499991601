<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <section id="knowledge-base-search">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <a class="btn btn-primary" [routerLink]="['/mlm/event-manage/create']">
                                    <i data-feather="plus" class="mr-1"></i>{{ "General.Create" | translate }}
                                </a>
                            </div>
                            <div class="card-body row">
                                <!-- <div class="col-12 col-sm-12 col-md-4">
                                <div class="scope-custom">
                                    <label for="">{{ 'General.SearchBy' | translate }}</label>
                                    <select class="custom-select" [(ngModel)]="searchByName">
                                        <option [ngValue]="0">{{ 'ViewTable.CustomerName' | translate }}</option>
                                        <option [ngValue]="1">{{ 'ViewTable.CustomerCode' | translate }}</option>
                                        <option [ngValue]="2">{{ 'ViewTable.OrderCode' | translate }}</option>
                                    </select>
                                </div>
                            </div> -->

                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "General.Search" | translate
                                            }}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" #searchbar name="name"
                                                placeholder="{{ 'General.Search' | translate }}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "General.Status" | translate
                                            }}</label>
                                        <select name="sorting" class="custom-select" #statusSelect>
                                            <option value="all">{{ "General.All" | translate }}</option>
                                            <option value="active">
                                                {{ "Status.Published"| translate}}
                                            </option>
                                            <option value="inactive">
                                                {{ "Status.Unpublished" | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "Stock.Type" | translate
                                            }}</label>
                                        <select name="sorting" class="custom-select" #typeSelect>
                                            <option value="type1">{{ "General.All" | translate }}</option>
                                            <option value="type2">{{ "General.All" | translate }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body row">

                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "PromotionForm.StartDate" | translate
                                            }}</label>
                                        <ng2-flatpickr class="date-form-control" [config]="dateOptions" name="startDate"
                                            formControlName="startDate" #startDatePicker></ng2-flatpickr>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "PromotionForm.EndDate" | translate
                                            }}</label>
                                        <ng2-flatpickr class="date-form-control" [config]="dateOptions" name="endDate"
                                            formControlName="endDate" #endDatePicker></ng2-flatpickr>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body row">
                                <div class="col-12 px-0 d-flex justify-content-between">
                                    <div class="col-12 px-0 d-flex justify-content-center">
                                        <button (click)="!isLoading ? ResetSearch() : false" [disabled]="isLoading"
                                            class="btn btn-secondary" style="margin-right: 0.5rem">
                                            <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" |
                                            translate }}
                                        </button>

                                        <!-- <button (click)="!isLoading ? SubmitSearch() : false" class="btn btn-primary" -->
                                        <button (click)="SubmitSearch()" class="btn btn-primary"
                                            style="margin-left: 0.5rem">
                                            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>

        <div class="card">
            <div class="card-body">
                <!-- datatable [dtOptions]="dtOptions" -->
                <table class="table" id="eventManageTable">
                    <thead>
                        <tr>
                            <th class="text-center">No</th>
                            <th>Event Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Create By</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="w-100" *ngIf="isLoading">
                            <td class="text-center" *ngFor="let num of [].constructor(7)">
                                <div class="skeleton-loading"></div>
                            </td>
                        </tr>

                        <tr *ngIf="eventList.length < 1 && !isLoading">
                            <td colspan="100%" class="text-center">
                                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                            </td>
                        </tr>


                        <tr *ngFor="let event of eventList; index as ind">
                            <td class="text-center">{{ ind + 1 }}</td>
                            <td class="text-primary">
                                <a [routerLink]="['/mlm/event-manage/attendance/' + event.id]">{{
                                    event.name }}</a>
                            </td>
                            <td>
                                <div>
                                    <div *ngIf="currentLang == 'th'">
                                        {{ event.startDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</div>
                                    <div *ngIf="currentLang == 'en'">
                                        {{ event.startDate | date : 'dd MMM YYYY HH:mm' }}
                                    </div>
                                </div>

                            </td>
                            <td>
                                <div>
                                    <div *ngIf="currentLang == 'th'">
                                        {{ event.endDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</div>
                                    <div *ngIf="currentLang == 'en'">
                                        {{ event.endDate | date : 'dd MMM YYYY HH:mm' }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <span style="display: inline-block; margin-left: 10px;">
                                    <div ngbDropdown>
                                        <a ngbDropdownToggle href="javascript:void(0);"
                                            class="d-flex justify-content-center text-secondary"
                                            id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" [ngClass]="{
                                    'text-success': event.isActive === true,
                                    'text-danger': event.isActive === false
                                  }">
                                            <div>
                                                {{
                                                (event.isActive == true
                                                ? "Status.Published"
                                                : "Status.Unpublished"
                                                ) | translate
                                                }}
                                            </div>
                                            <i data-feather="chevron-down"
                                                class="text-dark cursor-pointer ml-50 my-auto"></i>
                                        </a>
                                        <div ngbDropdownMenu class="dropdown-menu-left"
                                            aria-labelledby="dropdownBrowserState">
                                            <a ngbDropdownItem *ngIf="event.isActive == true"
                                                class="d-flex align-items-center"
                                                (click)="openSetActiveModal(ind, false)">
                                                <i data-feather="eye-off" class="mr-50"></i>
                                                {{ "Status.Unpublish" | translate }}
                                            </a>
                                            <a ngbDropdownItem *ngIf="event.isActive == false"
                                                class="d-flex align-items-center"
                                                (click)="openSetActiveModal(ind, true)">
                                                <i data-feather="eye" class="mr-50"></i>
                                                {{ "Status.Publish" | translate }}
                                            </a>
                                        </div>

                                    </div>
                                </span>
                            </td>
                            <td class="text-center">{{ event.createBy }}</td>
                            <td class="text-center">
                                <div class="d-flex justify-content-center">
                                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                                        [routerLink]="['/mlm/event-manage/attendance/' + event.id]"><i
                                            data-feather="eye"></i></a>
                                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50" [ngClass]="{
                                        'd-none': event.isDelete
                                      }" (click)="openDeleteContentModal(event)"><i data-feather="trash-2"
                                            class="text-danger"></i></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>