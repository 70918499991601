export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://azdemo-api.appintouch.net/api',
  // apiUrl: 'https://repove-api.appintouch.net/api',
  // apiUrl: 'https://192.168.1.220:3000/api',
  // apiUrl: 'https://192.168.1.226:7149/api',
  // apiUrl: 'https://repoveuat-api.appintouch.net/api',
  // apiUrl: 'https://192.168.1.148:5000/api',
  // apiUrl: 'https://192.168.1.220:7148/api',

  // apiUrl: 'https://192.168.1.226:7149/api',
  appName: 'ATV Back Office',
  appTitle: 'Asia Tire Venture Back Office',
  appLogoImage: 'assets/images/logo/atv-logo.png',
  appLogoNav: 'assets/images/logo/atv-logo.png',
  defaultIMG: 'assets/images/general/atv_default_img.png',

  // language list for content
  langDefault: 'en',
  langContent: ['en', 'th'],

  transaction: {
    logoOnPaperPath: '../assets/images/logo/Logo-ATV-compressed.png',
    logoOnFooterLeftPaperPath:
      '../assets/images/logo/Logo-ATV-compressed.png',
    logoOnFooterRightPaperPath: '../assets/images/logo/atv-logo.png',
    showLogoFooter: true,
    isUseWht: false,
    isSplitIVfromSO: false,
    isSplitGRfromPO: false,
    isJoinIV_TX_RE: true,
    isQuantityDecimal: false,
    shippingFee: 150,
  },

  stock: {
    isQuantityDecimal: false,
  },

  customer: {
    requiredDetail: false,
    requiredCentralContact: false,
  },

  limitFileSize: {
    maxImageSizeInBytes: 300 * 1024, //300KB
    maxVideoSizeInBytes: 2 * 1024 * 1024, //2MB
    maxFileSizeInBytes: 2 * 1024 * 1024, //2MB
  },

  menuList: {
    itemManage: true,
    cms: true,
    customer: true,
    transactionDoc: true,
    stock: true,
    mlm: true,
    codeManage: true,
    returnProduct: true,
    notification: true,
    bookingCalendar: true,
  },
};
