import { CoreMenu } from '@core/types';
import { environment } from 'environments/environment';

const isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
const isShowMenuList = environment.menuList;

export const menu: CoreMenu[] = [
  {
    id: 'general-section',
    type: 'section',
    title: 'General.General',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'General.Home',
        type: 'item',
        icon: 'home',
        url: 'home',
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'General.Settings',
        type: 'item',
        icon: 'settings',
        url: 'settings',
      },
    ],
  },

  {
    id: 'general-section',
    type: 'section',
    title: 'Dashboard.Title',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'home',
        title: 'Dashboard.ExecutiveSummaryDashboard',
        // translate: 'General.Home',
        type: 'item',
        icon: 'bar-chart-2',
        url: 'dashboard/executive-summary',
      },
      {
        id: 'settings',
        title: 'Dashboard.RevenueData',
        // translate: 'General.Settings',
        type: 'item',
        icon: 'bar-chart-2',
        url: 'dashboard/revenue',
      },
      {
        id: 'settings',
        title: 'Dashboard.MemberData',
        // translate: 'General.Settings',
        type: 'item',
        icon: 'bar-chart-2',
        url: 'dashboard/member',
      },
      {
        id: 'settings',
        title: 'Dashboard.BookingCalendarData',
        hidden: !isShowMenuList.bookingCalendar,
        type: 'collapsible',
        icon: 'bar-chart-2',
        role: ['SuperAdmin'],
        children: [
          {
            id: 'settings',
            title: 'Dashboard.BookingCalendarData',
            hidden: !isShowMenuList.bookingCalendar,
            type: 'item',
            icon: 'calendar',
            url: 'dashboard/booking-calendar',
          },
          {
            id: 'settings',
            title: 'Dashboard.ApproveBookingList',
            hidden: !isShowMenuList.bookingCalendar,
            type: 'item',
            icon: 'check-square',
            url: 'dashboard/booking-calendar-approve',
          },
        ]
      },
      {
        id: 'return-product-request',
        title: 'Dashboard.ReturnProductRequest',
        // translate: 'Notification.Title',
        hidden: !isShowMenuList.returnProduct,
        type: 'item',
        icon: 'dollar-sign',
        url: 'dashboard/refund-request',
      },
      // {
      //   id: 'event-manage',
      //   title: 'Event Manage',
      //   // translate: 'Notification.Title',
      //   // hidden: !isShowMenuList.codeManage,
      //   type: 'collapsible',
      //   icon: 'tv',
      //   children: [
      //     {
      //       id: 'event-manage-create',
      //       title: 'Create',
      //       // translate: 'General.Settings',
      //       type: 'item',
      //       icon: 'calendar',
      //       url: 'mlm/event-manage/create',
      //     },
      //     {
      //       id: 'event-manage-list',
      //       title: 'View',
      //       // translate: 'General.Settings',
      //       type: 'item',
      //       icon: 'tv',
      //       url: 'mlm/event-manage/view',
      //     },
      //   ]
      // },
    ],
  },

  {
    id: 'itemmanage-section',
    type: 'section',
    title: 'General.ItemManagement',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.itemManage,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'brand-management',
        translate: 'General.BrandManage',
        title: 'Manage Brand',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'award',
        url: 'item-manage/brand',
      },
      {
        id: 'group-management-collapse',
        title: 'Manage Group',
        translate: 'General.GroupManage',
        hidden: !isShowMenuList.itemManage,
        type: 'collapsible',
        icon: 'tag',
        children: [
          {
            id: 'group-management',
            translate: 'General.Group',
            title: 'Group',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/group',
          },
          {
            id: 'category-management',
            translate: 'General.Category',
            title: 'Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/category',
          },
          {
            id: 'subcategory-management',
            translate: 'General.SubCategory',
            title: 'Sub-Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subcategory',
          },
          {
            id: 'class-management',
            translate: 'General.Class',
            title: 'Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/class',
          },
          {
            id: 'subclass-management',
            translate: 'General.SubClass',
            title: 'Sub-Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subclass',
          },
        ],
      },
      {
        id: 'item-management',
        translate: 'General.ItemManage',
        title: 'Manage Item',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/item',
      },
      {
        id: 'item-set-management',
        translate: 'General.ItemSetManage',
        title: 'Manage Item Set',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/set',
      },
    ],
  },

  {
    id: 'code-manage-section',
    type: 'section',
    title: 'Code Manage',
    translate: 'General.ManageCode',
    hidden: !isShowMenuList.codeManage,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'promotion-code',
        title: 'Promotion Code',
        translate: 'General.PromotionCode',
        hidden: !isShowMenuList.codeManage,
        type: 'item',
        icon: 'tag',
        url: 'code-manage/promotion-code',
      },
    ],
  },

  {
    id: 'notification-section',
    type: 'section',
    title: 'Notification',
    translate: 'Notification.Title',
    hidden: !isShowMenuList.notification,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'notification',
        title: 'Notification',
        translate: 'Notification.Title',
        hidden: !isShowMenuList.notification,
        type: 'item',
        icon: 'bell',
        url: 'notification',
      },
      
    ],
  },

  
  {
    id: 'content-section',
    type: 'section',
    title: 'CMS',
    icon: 'airplay',
    hidden: !isShowMenuList.cms,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'content-section',
        title: 'Manage Content',
        translate: 'General.ContentManage',
        hidden: !isShowMenuList.cms,
        icon: 'airplay',
        type: 'collapsible',
        children: [
          {
            id: 'home-management',
            title: 'Home',
            translate: 'General.Home',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'home',
            url: 'cms/home',
          },
          {
            id: 'product-landing',
            title: 'Product',
            translate: 'General.Product',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'coffee',
            url: 'cms/product-landing',
          },
          {
            id: 'product-detail',
            title: 'Product Detail',
            translate: 'General.ProductDetail',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'file-text',
            url: 'cms/product-detail',
          },
       

          {
            id: 'about',
            title: 'About',
            translate: 'General.About',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'book-open',
            url: 'cms/about',
          },
          {
            id: 'news-management',
            title: 'News & Event',
            translate: 'General.NewsEvent',
            hidden: !isShowMenuList.cms,
            icon: 'trello',
            type: 'collapsible',
            children: [
              {
                id: 'news-landing-management',
                title: 'Landing Page',
                translate: 'General.LandingPage',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/news-landing',
              },
              {
                id: 'news-management',
                title: 'News & Event',
                translate: 'General.NewsEvent',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/news',
              },
              {
                id: 'consumer-review',
                title: 'Consumer Review',
                translate: 'General.ConsumerReview',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/consumer-review',
              },
              {
                id: 'company-video',
                title: 'Company Video',
                translate: 'General.CompanyVideo',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/company-video',
              },
            ],
          },

          {
            id: 'contact-management',
            title: 'Contact',
            translate: 'General.Contact',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'book-open',
            url: 'cms/contact',
          },
          {
            id: 'privacy-policy',
            title: 'Privacy & Policy',
            translate: 'General.PrivacyPolicy',
            hidden: !isShowMenuList.cms,
            icon: 'user-check',
            type: 'collapsible',
            children: [
              {
                id: 'privacy_policy',
                title: 'Privacy & Policy',
                translate: 'General.PrivacyPolicy',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'user-check',
                url: 'cms/privacy-policy',
              },
              {
                id: 'cookie-policy',
                title: 'Cookie Policy',
                translate: 'General.CookiePolicy',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'user-check',
                url: 'cms/cookie-policy',
              },
              {
                id: 'terms-condition',
                title: 'Terms & Condition',
                translate: 'General.TermsCondition',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'user-check',
                url: 'cms/terms-condition',
              },
              {
                id: 'seller-manage-regulation',
                title: 'Seller Management Regulation',
                hidden: !isShowMenuList.cms,
                translate: 'General.SellerManage',
                type: 'item',
                // icon: 'user-check',
                url: 'cms/seller-manage-regulation',
              },
            ],
          },
          {
            id: 'shopping-cart',
            title: 'Shopping Cart',
            translate: 'General.ShoppingCart',
            hidden: !isShowMenuList.cms,
            // translate: 'General.Event',
            type: 'item',
            icon: 'coffee',
            url: 'cms/shopping-cart',
          },
          {
            id: 'sign-in',
            title: 'Sign In',
            translate: 'General.SignIn',
            hidden: !isShowMenuList.cms,
            // translate: 'General.Event',
            type: 'item',
            icon: 'user-check',
            url: 'cms/sign-in',
          },
          {
            id: 'help-center',
            title: 'Help Center',
            translate: 'General.HelpCenter',
            hidden: !isShowMenuList.cms,
            icon: 'users',
            type: 'collapsible',
            children: [
              {
                id: 'categories',
                title: 'Categories',
                translate: 'Informative.HelpCenter.Categories.Label',
                hidden: !isShowMenuList.cms,
                type: 'item',
                icon: 'speech',
                url: 'cms/categories',
              },
              {
                id: 'bonus-plan',
                title: 'Bonus Plan',
                translate: 'General.BonusPlan',
                hidden: !isShowMenuList.cms,
                type: 'item',
                icon: 'speech',
                url: 'cms/bonus-plan',
              },
              {
                id: 'faq',
                title: 'FAQ',
                translate: 'General.FAQ',
                hidden: !isShowMenuList.cms,
                type: 'item',
                icon: 'speech',
                url: 'cms/faq',
              },
            ],
          },
        ],
      },

      {
        id: 'repove-tools-section',
        title: 'Content Tools',
        // translate: 'General.RepoveTool',
        hidden: !isShowMenuList.cms,
        icon: 'airplay',
        type: 'collapsible',
        children: [
          {
            id: 'repove-tools-landing',
            title: 'Landing Page',
            translate: 'General.RepoveToolsLandingPage',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'home',
            url: 'cms/repove-tools/landing',
          },
          {
            id: 'tech-product-landing',
            title: 'Tech&Product Landing Page',
            translate: 'General.TechProductLandingPage',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'airplay',
            url: 'cms/repove-tools/tech-product-landing',
          },
          {
            id: 'bonus-plan-landing',
            title: 'Bonus Plan',
            translate: 'General.BonusPlan',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'dollar-sign',
            url: 'cms/repove-tools/bonus-plan',
          },
          {
            id: 'our-company',
            title: 'Our Company',
            translate: 'General.OurCompany',
            hidden: !isShowMenuList.cms,
            type: 'collapsible',
            icon: 'globe',
            // url: 'cms/repove-tools/our-company',
            children: [
              {
                id: 'our-company-timeline',
                title: 'Timeline',
                translate: 'General.Timeline',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/our-company/timeline',
              },
              {
                id: 'our-company-card',
                title: 'Company Card',
                translate: 'General.CompanyCard',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/our-company/company-card',
              },
              {
                id: 'our-company-branch',
                title: 'Branch',
                translate: 'General.OfficeBranch',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/our-company/branch',
              },
              {
                id: 'our-company-detail',
                title: 'Detail',
                translate: 'General.Detail',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/our-company/detail',
              },
            ],
          },
          {
            id: 'repove-tools-product',
            title: 'Product',
            translate: 'General.Product',
            hidden: !isShowMenuList.cms,
            type: 'item',
            icon: 'box',
            url: 'cms/repove-tools/product-list',
           
          },
          {
            id: 'repove-tools-technology',
            title: 'Technology',
            translate: 'General.Technology',
            hidden: !isShowMenuList.cms,
            type: 'collapsible',
            icon: 'terminal',
            // url: 'cms/repove-tools/our-company',
            children: [
              {
                id: 'repove-tools-technology-hydroethosome',
                title: 'Hydroethosome',
                // translate: 'General.Home',
                hidden: !isShowMenuList.cms,
                type: 'collapsible',
         
                children: [
                  {
                    id: 'repove-tools-technology-hydropartisome-detail',
                    title: 'Detail',
                    translate: 'General.Detail',
                    hidden: !isShowMenuList.cms,
                    type: 'item',

                    url: 'cms/repove-tools/technology/hydroethosome/detail',
                  },
                  {
                    id: 'repove-tools-technology-hydropartisome-highlight-tech',
                    title: 'Highlight Tech',
                    translate: 'General.Highlight',
                    hidden: !isShowMenuList.cms,
                    type: 'item',

                    url: 'cms/repove-tools/technology/hydroethosome/highlight-tech',
                  },
                  {
                    id: 'repove-tools-technology-hydropartisome-etc-tech',
                    title: 'ETC Tech',
                    translate: 'General.ETCTechnology',
                    hidden: !isShowMenuList.cms,
                    type: 'item',

                    url: 'cms/repove-tools/technology/hydroethosome/etc-tech',
                  },
                ],
              },
              {
                id: 'repove-tools-technology-hydropartisome',
                title: 'Hydropartisome',
                // translate: 'General.Home',
                hidden: !isShowMenuList.cms,
                type: 'item',

                url: 'cms/repove-tools/technology/hydropartisome',
              },
              {
                id: 'repove-tools-technology-hydroextraction',
                title: 'Hydroextraction',
                // translate: 'General.Home',
                hidden: !isShowMenuList.cms,
                type: 'item',
 
                url: 'cms/repove-tools/technology/hydroextraction',
              },
              {
                id: 'repove-tools-technology-partibiome',
                title: 'Partibiome',
                // translate: 'General.Home',
                hidden: !isShowMenuList.cms,
                type: 'item',
        
                url: 'cms/repove-tools/technology/partibiome',
              },
            ],
          },
          {
            id: 'certification',
            title: 'Certification',
            translate: 'General.Certification',
            hidden: !isShowMenuList.cms,
            type: 'collapsible',
            icon: 'book-open',
            // url: 'cms/repove-tools/our-company',
            children: [
              {
                id: 'certification-card',
                title: 'Cert Card',
                translate: 'General.CertificationCard',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/certification/card',
              },
              {
                id: 'certification-file',
                title: 'Cert Files',
                translate: 'General.CertificationFile',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/repove-tools/certification/file',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'customer-section',
    type: 'section',
    title: 'Customer',
    translate: 'General.Customer',
    hidden: !isShowMenuList.customer,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'customer',
        title: 'Customer',
        translate: 'General.Customer',
        hidden: !isShowMenuList.customer,
        type: 'item',
        icon: 'users',
        url: 'customer',
      },
    ],
  },

  {
    id: 'transaction',
    type: 'section',
    title: 'General.Sale', // title: 'Transaction',
    // translate: 'MENU.OTHERS.SECTION',
    hidden: !isShowMenuList.transactionDoc,
    icon: 'box',
    children: [
      {
        id: 'document',
        title: 'Revenue Document',
        translate: 'General.RevenueDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [
          {
            id: 'quotation',
            title: 'Quotation',
            translate: 'Menu.Document.QO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'quotation-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/create',
              },
              {
                id: 'quotation-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/view',
              },
            ],
          },

          {
            id: 'saleorder',
            title: 'Sale Order',
            translate: 'Menu.Document.SO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'saleorder-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/saleorder/view',
              },
            ],
          },

          {
            id: 'invoice',
            title: isJoinIV_TX_RE ? 'Invoice/Tax Invoice/Receipt' : 'Invoice',
            translate: isJoinIV_TX_RE
              ? 'Document.IV_TX_RE'
              : 'Menu.Document.IV',
            icon: 'circle',
            type: 'collapsible',
            hidden: !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'invoice-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/invoice/view',
              },
            ],
          },

          {
            id: 'tax',
            title: 'Tax Invoice',
            translate: 'Menu.Document.TX',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'tax-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/tax/view',
              },
            ],
          },

          {
            id: 'receipt',
            title: 'Receipt',
            translate: 'Menu.Document.RC',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'receipt-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/receipt/view',
              },
            ],
          },

          {
            id: 'credit-note',
            title: 'Credit Note',
            translate: 'Menu.Document.CNT',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'credit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/create',
              },
              {
                id: 'credit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/view',
              },
            ],
          },

          {
            id: 'debit-note',
            title: 'Debit Note',
            translate: 'Menu.Document.DBN',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'debit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/create',
              },
              {
                id: 'debit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/view',
              },
            ],
          },
        ],
      },
      {
        id: 'document-expenses',
        title: 'Expenses Document',
        translate: 'General.ExpensesDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [
          {
            id: 'purchase-request',
            title: 'Purchase Request',
            translate: 'Menu.Document.PR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'pr-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/create',
              },
              {
                id: 'pr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/view',
              },
            ],
          },
          {
            id: 'purchase-order',
            title: 'Purchase Order',
            translate: 'Menu.Document.PO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'po-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-order/view',
              },
            ],
          },
          {
            id: 'goods-receive',
            title: 'Goods Receive',
            translate: 'Menu.Document.GR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'gr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/goods-receive/view',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 'stock-management-section',
    type: 'section',
    title: 'Stock',
    hidden: !isShowMenuList.stock,
    translate: 'General.Stock',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'stock-management',
        title: 'Stock',
        hidden: !isShowMenuList.stock,
        translate: 'General.Stock',
        type: 'item',
        icon: 'box',
        url: 'stock',
      },
    ],
  },

  {
    id: 'mlm-section',
    type: 'section',
    title: 'MLM Configuration',
    translate: 'MLM.Configuration',
    hidden: !isShowMenuList.mlm,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'bonus-plan-manage',
        title: 'Bonus Plan Manage',
        translate: 'BonusPlan.BonusPlanManage',
        hidden: !isShowMenuList.mlm,
        type: 'collapsible',
        icon: 'layout',
        children: [
          {
            id: 'personal-bonus-table',
            title: 'Personal Bonus Table',
            translate: 'BonusPlan.PersonalBonusManage',
            hidden: !isShowMenuList.mlm,
            type: 'item',
            icon: 'users',
            url: 'mlm-config/bonus-table-config',
          },
          {
            id: 'downline-bonus-table',
            title: 'Downline Bonus Table',
            translate: 'BonusPlan.DownlineBonusManage',
            hidden: !isShowMenuList.mlm,
            type: 'item',
            icon: 'git-merge',
            url: 'mlm-config/downline-bonus-table-config',
          },
          {
            id: 'system-bonus-table',
            title: 'System Bonus Table',
            translate: 'BonusPlan.SystemBonusManage',
            hidden: !isShowMenuList.mlm,
            type: 'item',
            icon: 'airplay',
            url: 'mlm-config/system-bonus-table-config',
          },
          {
            id: 'sv-exchange-rate',
            title: 'SV Exchange Rate',
            translate: 'BonusPlan.SVExchange',
            hidden: !isShowMenuList.mlm,
            type: 'item',
            icon: 'dollar-sign',
            url: 'mlm-config/sv-exchange-rate-config',
          },
        ],
      },
      {
        id: 'rank-manage',
        title: 'Rank Manage',
        translate: 'MLM.RankManage',
        type: 'item',
        icon: 'award',
        hidden: !isShowMenuList.mlm,
        url: 'mlm-config/rank-manage',
      },
      {
        id: 'sale-period-manage',
        title: 'Sale Period Manage',
        translate: 'MLM.SalePeriodManage',
        type: 'item',
        icon: 'clock',
        hidden: !isShowMenuList.mlm,
        url: 'mlm-config/sale-period-manage',
      },
    ],
  },

  // {
  //   id: 'frontend-control-panel-section',
  //   type: 'section',
  //   title: 'Frontend Control Panel',
  //   translate: 'Menu.FrontendControlPanel',
  //   icon: 'bar-chart-2',
  //   role: ['SuperAdmin'],
  //   children: [
  //     {
  //       id: 'frontend-role-management',
  //       title: 'Manage Roles',
  //       translate: 'General.RolesManage',
  //       type: 'item',
  //       icon: 'key',
  //       url: 'admin/frontend/roleset',
  //     },
  //     {
  //       id: 'frontend-user-management',
  //       title: 'Manage User',
  //       translate: 'General.UserManage',
  //       type: 'item',
  //       icon: 'user',
  //       url: 'admin/frontend/users',
  //     },
  //   ],
  // },

  {
    id: 'control-panel-section',
    type: 'section',
    title: 'Control Panel',
    translate: 'General.ControlPanel',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      // {
      //   id: 'trash',
      //   title: 'Trash',
      //   translate: 'General.Trash',
      //   type: 'item',
      //   icon: 'trash-2',
      //   url: 'admin/trash',
      // },
      // {
      //   id: 'acl-management',
      //   title: 'Manage ACL',
      //   translate: 'General.ACLManage',
      //   type: 'item',
      //   icon: 'key',
      //   url: 'admin/acl',
      // },
      {
        id: 'system-settings',
        title: 'System Settings',
        translate: 'General.SystemSettings',
        type: 'item',
        icon: 'key',
        url: 'admin/system-settings',
      },
      {
        id: 'role-management',
        title: 'Manage Roles',
        translate: 'General.RolesManage',
        type: 'item',
        icon: 'key',
        url: 'admin/roleset',
      },
      {
        id: 'user-management',
        title: 'Manage User',
        translate: 'General.UserManage',
        type: 'item',
        icon: 'user',
        url: 'admin/users',
      },
    ],
  },
];
