<div class="d-flex justify-content-end">
  <a (click)="toggleFormState()" class="btn btn-primary">{{
    "General.Edit" | translate
    }}</a>
</div>

<form [formGroup]="formGroup">
  <div class="form-group row">
    <div class="col-md-8">
      <div class="col-form-label">
        {{ "Form.Name" | translate }}
      </div>
      <input class="form-control" formControlName="name" type="text" [ngClass]="{
          'is-invalid error': ''
        }" />
      <div class="invalid-feedback" [ngClass]="{
          'd-block': ''
        }">
        <div>
          <div class="d-none">
            {{ "Customer.nameRequiredError" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="col-form-label">
        {{ "Customer.idNumber" | translate }}
        <span class="text-danger">*</span>
      </div>
      <input class="form-control" formControlName="taxId" type="text" [ngClass]="{
          'is-invalid error': ''
        }" />
      <div class="invalid-feedback" [ngClass]="{
          'd-block': ''
        }">
        <div>
          <div class="d-none">
            {{ "Customer.nameRequiredError" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="form-group row">
    <div class="col-md-4">
      <div class="col-form-label">
        {{ "User.Email" | translate }}
        <span class="text-danger">*</span>
      </div>
      <input class="form-control" formControlName="email" type="text" [ngClass]="{
          'is-invalid error': ''
        }" />
    </div>

    <div class="col-md-4">
      <div class="col-form-label">
        {{ "User.Phone" | translate }}
        <span class="text-danger">*</span>
      </div>
      <input class="form-control" formControlName="phone" type="text" [ngClass]="{
          'is-invalid error': ''
        }" />
    </div>


    <div class="col-md-4">
      <div class="col-form-label">{{ "Customer.website" | translate }}</div>
      <input class="form-control" formControlName="website" type="text" [ngClass]="{
          'is-invalid error': ''
        }" />
    </div>
  </div>

  <hr />

  <div class="form-group row ctm-addr">
    <div class="col-md-6">
      <div class="col-form-label">{{ "Address.address" | translate }}</div>
      <textarea class="form-control" formControlName="addressNumber" rows="8" [ngClass]="{
          'is-invalid error': ''
        }"></textarea>
    </div>

    <div class="col-md-6">
      <div class="col-form-label">
        {{ "Address.province" | translate }}
        <span class="text-danger">*</span>
      </div>
      <div class="skeleton-select" *ngIf="isLoadingProvince">
        <div class="spinner-border spinner-border-sm mr-50" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <ng-select *ngIf="!isLoadingProvince" class="custom-select" [clearable]="false"
        [style.background]="formDisabled ? '#efefef' : '#fff'"
        placeholder="   -- {{ 'Address.selectProvince' | translate }} --" #c_provinceInput formControlName="provinceId"
        [ngClass]="{
          'is-invalid error':
            (isSubmit && f.provinceId?.invalid) ||
            (f.provinceId?.invalid &&
              (f.provinceId?.dirty || f.provinceId?.touched)) ||
            (f.provinceId?.value == '' &&
              (f.provinceId?.dirty || f.provinceId?.touched))
        }" (change)="isSelectProvice($event)" [hideSelected]="false">
        <ng-option [value]="province.id" *ngFor="let province of provinceList">
          <span *ngIf="currentLang != 'en'">
            {{ province.provinceName }}
          </span>
          <span *ngIf="currentLang != 'th'">
            {{ province.provinceNameEn }}
          </span>
        </ng-option>
      </ng-select>

      <div class="invalid-feedback" [ngClass]="{
          'd-block':
            (isSubmit && f.provinceId?.invalid) ||
            (f.provinceId.invalid &&
              (f.provinceId.dirty || f.provinceId.touched))
        }">
        <div>
          <div *ngIf="f.provinceId.errors?.required">
            {{ "Address.cCityRequiredError" | translate }}
          </div>
        </div>
      </div>

      <div class="col-form-label">
        {{ "Address.district" | translate }}
        <span class="text-danger">*</span>
      </div>
      <div class="skeleton-select" *ngIf="isLoadingDistrict">
        <div class="spinner-border spinner-border-sm mr-50" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ng-select *ngIf="!isLoadingDistrict" class="custom-select" #c_districtInput [clearable]="false"
        [style.background]="formDisabled ? '#efefef' : '#fff'"
        placeholder="-- {{ 'Address.selectDistrict' | translate }} --" formControlName="districtId"
        (change)="isSelectDistrict($event)" [ngClass]="{
          'is-invalid error':
            (isSubmit && f.districtId?.invalid) ||
            (f.districtId?.invalid &&
              (f.districtId?.dirty || f.districtId?.touched)) ||
            (f.districtId?.value == '' &&
              (f.districtId?.dirty || f.districtId?.touched))
        }" [hideSelected]="false">
        <ng-option [value]="district.id" *ngFor="let district of districtList">
          <span *ngIf="currentLang != 'en'">
            {{ district.districtName }}
          </span>
          <span *ngIf="currentLang != 'th'">
            {{ district.districtNameEn }}
          </span>
        </ng-option>
      </ng-select>

      <div class="invalid-feedback" [ngClass]="{
          'd-block':
            (isSubmit && f.districtId?.invalid) ||
            (f.districtId.invalid &&
              (f.districtId.dirty || f.districtId.touched))
        }">
        <div>
          <div *ngIf="f.districtId.errors?.required">
            {{ "Address.cDistrictRequiredError" | translate }}
          </div>
        </div>
      </div>

      <div class="col-form-label">
        {{ "Address.subdistrict" | translate }}
        <span class="text-danger">*</span>
        <br />
      </div>
      <div class="skeleton-select" *ngIf="isLoadingSubDistrict">
        <div class="spinner-border spinner-border-sm mr-50" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ng-select *ngIf="!isLoadingSubDistrict" class="custom-select" [clearable]="false"
        placeholder="-- {{ 'Address.selectSubDistrict' | translate }} --" #c_subDistrictInput
        [style.background]="formDisabled ? '#efefef' : '#fff'" formControlName="subDistrictId"
        (change)="getPostCode($event)" [hideSelected]="false" [ngClass]="{
          'is-invalid error':
            (isSubmit && f.subDistrictId?.invalid) ||
            (f.subDistrictId?.invalid &&
              (f.subDistrictId?.dirty || f.subDistrictId?.touched)) ||
            (f.subDistrictId?.value == '' &&
              (f.subDistrictId?.dirty || f.subDistrictId?.touched))
        }">
        <ng-option [value]="subDistrict.id" *ngFor="let subDistrict of subDistrictList">
          <span *ngIf="currentLang != 'en'">
            {{ subDistrict.subDistrictName }}
          </span>
          <span *ngIf="currentLang != 'th'">
            {{ subDistrict.subDistrictNameEn }}
          </span>
        </ng-option>
      </ng-select>

      <div class="invalid-feedback" [ngClass]="{
          'd-block':
            (isSubmit && f.subDistrictId?.invalid) ||
            (f.subDistrictId.invalid &&
              (f.subDistrictId.dirty || f.subDistrictId.touched))
        }">
        <div>
          <div *ngIf="f.subDistrictId.errors?.required">
            {{ "Address.cSubDistrictRequiredError" | translate }}
          </div>
        </div>
      </div>

      <div class="col-form-label">
        {{ "Address.postalCode" | translate }}
        <span class="text-danger">*</span>
      </div>
      <input #c_postcodeInput class="form-control" formControlName="postCode" type="text" disabled [ngClass]="{
          'is-invalid error':
            (isSubmit && f.postCode?.invalid) ||
            (f.postCode?.invalid &&
              (f.postCode?.dirty || f.postCode?.touched)) ||
            (f.postCode?.value == '' &&
              (f.postCode?.dirty || f.postCode?.touched))
        }" />
      <div class="invalid-feedback" [ngClass]="{
          'd-block':
            (isSubmit && f.postCode?.invalid) ||
            (f.postCode.invalid && (f.postCode.dirty || f.postCode.touched))
        }">
        <div>
          <div *ngIf="f.postCode.errors?.required">
            {{ "Address.cPostcodeRequiredError" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="d-flex justify-content-end">
    <button (click)="openConfirmModal()" class="btn btn-primary" [disabled]="formDisabled">
      {{ "Form.Submit" | translate }}
    </button>
  </div>
</form>