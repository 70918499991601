<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>

  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <table class="table table-bordered system-bonus-table">
          <thead>
            <tr>
              <th width="60%">{{ "BonusPlan.Condition" | translate }}</th>
              <th width="12%" class="text-center active-pv">AP</th>
              <th width="12%" class="text-center master-pv">MP</th>
              <th width="12%" class="text-center ">{{ "General.Manage" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bonus of systemBonusDataList; let i = index" [class.selected-row]="i === selectedRowIndex">
              <td>{{ ('MLM.SystemBonusCondition.'+bonus.conditionName) | translate }}</td>
              <td class="text-center active-pv">
                <span *ngIf="selectedRowIndex !== i; else editAPMode">
                  {{ bonus.apPercentage }}%
                </span>
                <ng-template #editAPMode>
                  <input class="form-control text-end" type="number" (change)="onDataChange()"
                    [(ngModel)]="bonus.apPercentage" />
                </ng-template>
              </td>
              <td class="text-center master-pv">
                <span *ngIf="selectedRowIndex !== i; else editMPMode">
                  {{ bonus.mpPercentage }}%
                </span>
                <ng-template #editMPMode>
                  <input class="form-control text-end" type="number" (change)="onDataChange()"
                    [(ngModel)]="bonus.mpPercentage" />
                </ng-template>
              </td>
              <td class="action-column-butt d-flex justify-content-center">
                <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50" *ngIf="selectedRowIndex === i"
                  (click)="saveRow(i)">
                  <i data-feather="save" class="text-success"></i>
                </a>
                <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50" *ngIf="selectedRowIndex === i"
                  (click)="cancelEdit(i)">
                  <i data-feather="x-square" class="text-danger"></i>
                </a>
                <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50" *ngIf="selectedRowIndex !== i"
                  (click)="editData(i)">
                  <i data-feather="edit" class="text-warning"></i>
                </a>

              </td>
            </tr>

            <tr>
              <td class="text-bold text-dark">{{ "General.Total" | translate }}</td>
              <td class="text-center text-bold text-dark active-pv">
                {{ getAPSum() }}%
              </td>
              <td class="text-center text-bold text-dark master-pv">
                {{ getMPSum() }}%
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card">
      <div class="card-header">{{ "BonusPlan.SystemBonusHistory" | translate }}</div>
      <div class="card-body" *ngIf="logDataList.length >= 1">
        <table class="table" datatable [dtOptions]="dtOptions">
          <thead>
            <tr>
              <th class="text-center" width="5%">#</th>
              <th width="30%">{{ "BonusPlan.Condition" | translate }}</th>
              <th class="text-center" width="10%">AP</th>
              <th class="text-center" width="10%">MP</th>
              <th class="text-center" width="15%">{{ "BonusPlan.UpdateBy" | translate }}</th>
              <th class="text-center" width="15%">{{ "BonusPlan.UpdateDate" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="w-100" *ngIf="isLoading">
              <td class="text-center" *ngFor="let num of [].constructor(6)">
                <div class="skeleton-loading"></div>
              </td>
            </tr>

            <tr *ngIf="logDataList.length < 1 && !isLoading">
              <td colspan="100%" class="text-center">
                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
              </td>
            </tr>

            <tr *ngFor="let log of logDataList; index as ind">
              <td class="text-center">{{ ind + 1 }}</td>
              <td>{{ ('MLM.SystemBonusCondition.'+log.conditionName) | translate }}</td>
              <td class="text-center active-pv">{{ log.fromAPPercentage }}% <i data-feather="arrow-right"
                  class="mx-1"></i> {{
                log.toAPPercentage }}%</td>
              <td class="text-center master-pv">{{ log.fromMPPercentage }}% <i data-feather="arrow-right"
                  class="mx-1"></i> {{
                log.toMPPercentage }}%</td>
              <td class="text-center">{{ log.createBy ? log.createBy : '-'}}</td>
              <td class="text-center">
                <div *ngIf="currentLang != 'th'">
                  {{ log.createDate | date : "dd MMM YYYY HH:mm" }}
                </div>
                <div *ngIf="currentLang != 'en'">
                  {{ log.createDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                </div>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
</div>